import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "../src/lib/antd";
import http from "./lib/axios";
import axios from "axios";
import common from "./lib/common";
import VueCodeMirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/ambiance.css"; // 这里引入的是主题样式，根据设置的theme的主题引入
import "codemirror/addon/display/placeholder.js"; // 这里引入的是占位符
require("codemirror/mode/javascript/javascript"); // 这里引入的模式的js，根据设置的mode引入
require("codemirror/addon/selection/active-line"); //设置光标所在行高亮
import syncLoading from "./lib/syncLoading.js"; // 引入loading
import dataV from "@jiaminghi/data-view"; //引入dataV
import contentmenu from "v-contextmenu";
import "v-contextmenu/dist/index.css";
import Directives from "./directives";
import rightMenu from "./pages/rightMenu/index";
import VueDraggableResizable from "vue-draggable-resizable";
import VueDND from "awe-dnd";
import VueClipboard from "vue-clipboard2";

// 导入富文本编译器
import VueQuillEditor from "vue-quill-editor";

// require styles 导入富文本编译器对应的样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import infiniteScroll from "vue-infinite-scroll";
import { Icon } from "ant-design-vue";
import hevueImgPreview from "hevue-img-preview";
// 水印
import watermark from "../src/lib/watermark";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3576187_hrgymz27r4.js",
});

Vue.prototype.$watermark = watermark;
// 将富文本编辑器注册为全局可用的组件
Vue.use(VueQuillEditor);
Vue.use(infiniteScroll);
Vue.use(hevueImgPreview);
Vue.component("vue-draggable-resizable", VueDraggableResizable);

Vue.component("icon-font", IconFont);

// //全局注册svg-icon

Vue.use(contentmenu);

Vue.use(syncLoading); // 全局使用loading
Vue.use(VueCodeMirror);
Vue.use(dataV);
Vue.use(Directives);
// 右击菜单
Vue.use(VueClipboard);
Vue.use(rightMenu);

Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.prototype.$common = common;

Vue.config.productionTip = false;

Vue.use(antd);
Vue.use(VueDND);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
