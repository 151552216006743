<template>
  <div class="moduleList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目名称：
        <a-input
          placeholder="请输入项目名称"
          v-model.trim="projectName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        模块名称：
        <a-input
          placeholder="请输入模块名称"
          v-model.trim="moduleName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" style="padding: 0" @click="deploy(record)"
          >deploy</a-button
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deployLog(record)">deploy日志</a>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="deploy日志"
      v-model="deployLogVisible"
      :maskClosable="false"
      width="1500px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(deployLogTableColumns)"
        :columns="deployLogTableColumns"
        :dataSource="deployLogTableDataSource"
        :pagination="deployLogTablePagination"
        :loading="deployLogLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="commitInfo" slot-scope="text, record" style="width: 500px">
          <a-tooltip :overlayStyle="{ maxWidth: 'none' }">
            <template slot="title"
              ><div v-html="text" style="white-space: nowrap"></div
            ></template>
            <span
              style="
                display: inline-block;
                width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ text }}</span
            >
          </a-tooltip>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="deployLogVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="deploy"
      v-model="deployVisible"
      :maskClosable="false"
      :afterClose="deployClose"
      width="1000px"
    >
      <div class="desc" style="margin-top: 20px">
        <header class="desc_header">
          <div style="float: left">推送信息</div>
        </header>
        <div class="desc_content">
          <a-select
            v-model="branch"
            placeholder="请选择分支"
            style="width: 150px; margin-right: 10px"
            @change="deploy()"
          >
            <a-select-option v-for="item in branchList" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
          <span v-html="commitId"></span>
        </div>
      </div>
      <div class="desc" style="margin-top: 20px">
        <header class="desc_header">
          <div style="float: left">Maven依赖</div>
          <div style="float: right; cursor: pointer" v-copy="rely">
            <a-icon type="copy" />
          </div>
        </header>
        <div class="desc_content">
          <pre
            class="xml"
            style="
              display: block;
              overflow-x: auto;
              background: #f7f7f7;
              color: #444444;
            "
          ><code>  <span class="hljs-tag">&lt;<span style="font-weight: bold;">dependency</span>&gt;</span>
      <span class="hljs-tag">&lt;<span style="font-weight: bold;">groupId</span>&gt;</span>{{groupId}}<span class="hljs-tag">&lt;/<span style="font-weight: bold;">groupId</span>&gt;</span>
      <span class="hljs-tag">&lt;<span style="font-weight: bold;">artifactId</span>&gt;</span>{{artifactId}}<span class="hljs-tag">&lt;/<span style="font-weight: bold;">artifactId</span>&gt;</span>
      <span class="hljs-tag">&lt;<span style="font-weight: bold;">version</span>&gt;</span>{{version}}<span class="hljs-tag">&lt;/<span style="font-weight: bold;">version</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span style="font-weight: bold;">dependency</span>&gt;</span></code></pre>
        </div>
      </div>
      <div class="desc" style="margin-top: 20px">
        <header class="desc_header">
          <div style="float: left">编译日志</div>
          <div style="float: right; cursor: pointer" v-copy="rely">
            <a-icon type="copy" />
          </div>
        </header>
        <div class="desc_content">
          <div id="terminal" style="height: 300px"></div>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="deploy_submit" :loading="loading">
            {{ loading ? "编译中" : "编译并上传" }}
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/moduleList.js";
import "../../node_modules/xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

export default {
  name: "moduleList",
  data() {
    return {
      projectName: "",
      projectNameAdd: "",
      moduleName: "",
      moduleNameAdd: "",
      deployAble: undefined,
      deployAbleAdd: undefined,
      deployAbleList: [],
      deployLogVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "项目名称",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "模块名称",
          ellipsis: true,
          dataIndex: "moduleName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "gmtModified",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      key: "deployUpload",
      deployLogTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "version",
        },
        {
          title: "推送信息",
          ellipsis: true,
          dataIndex: "commitInfo",
          scopedSlots: { customRender: "commitInfo" },
        },
        {
          title: "操作人",
          ellipsis: true,
          dataIndex: "userName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      deployLogTableDataSource: [],
      deployLogTablePagination: {},
      deployLogLoadingTable: false,
      deployLogPageNo: 1,
      version: "",
      artifactId: "",
      groupId: "",
      commitId: "",
      deployVisible: false,
      rely: "",
      term: null,
      timer: null,
      moduleId: "",
      deployFlag: true,
      deployLogArr: [],
      branch: "",
      branchList: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    deployClose() {
      this.term.dispose();
    },
    deploy_submit() {
      this.deployFlag = true;
      this.loading = true;
      this.deployLogArr.splice(0);
      this.term.write("\x1b[2K\r");
      this.term.clear();
      this.autoRefresh();
      let data = {
        moduleId: this.moduleId,
        branch: this.branch,
      };
      api.deploy(data).then((res) => {
        if (res.result == 200) {
          this.deployFlag = false;
          this.loading = false;
          if (res.data) {
            this.$message.success("编译成功");
          } else {
            this.$message.success("编译失败");
          }
        }
      });
    },
    // 编译自动刷新
    autoRefresh() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this.getDeployDetail();
      }, 1000);
    },
    getDeployDetail() {
      let data = {
        moduleId: this.moduleId,
        size: this.deployLogArr.length,
      };
      api.deployDetail(data).then((res) => {
        if (res.result == 200) {
          this.deployLogArr.push(...res.data);
          res.data.forEach((element) => {
            this.term.write(element + "\r\n");
          });
          if (this.deployFlag) {
            this.autoRefresh();
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }
      });
    },
    deploy(val) {
      if (val) {
        this.moduleId = val.id;
        this.branch = null;
      }
      api
        .detail({ moduleId: this.moduleId, branch: this.branch })
        .then((res) => {
          if (res.result == 200) {
            this.commitId = "";
            res.data.commitInfo.forEach((item) => {
              this.commitId = this.commitId + item + "<br>";
            });
            if (val) {
              this.branch = res.data.branchList[0];
            }
            this.branchList = res.data.branchList;
            this.artifactId = res.data.artifactId;
            this.version = res.data.version;
            this.groupId = res.data.groupId;
            this.result = "";
            this.deployLogArr.splice(0);
            this.rely = `<dependency>
                          <groupId>${this.groupId}</groupId>
                          <artifactId>${this.artifactId}</artifactId>
                          <version>${this.version}</version>
                        </dependency>`;
            if (!this.deployVisible) {
              this.deployVisible = true;
              this.$nextTick(() => {
                this.term = new Terminal({
                  theme: {
                    background: "#008080", //背景色
                    black: "#003440",
                    blue: "#268ad2",
                    brightBlack: "#00779a",
                    brightBlue: "#109fd2",
                    BrightCyan: "#00bdae",
                    cyan: "#2aa197",
                    brightGreen: "#5bee96",
                    green: "#7cc67f",
                    brightMagenta: "#e9679f",
                    megenta: "#d33582",
                    brightRed: "#f9314b",
                    red: "#dc312e",
                    brightWhite: "#fdf6e3",
                    white: "#eee8d5",
                    brightYellow: "#c08f34",
                    yellow: "#b58900",
                    foreground: "#f0f0f0", //字体
                    selection: "#2c9aff66",
                    cursor: "#f86100",
                    cursorAccent: "#003440",
                  },
                });
                const fitAddon = new FitAddon();
                this.term.loadAddon(fitAddon);
                this.term.open(document.getElementById("terminal"));
                fitAddon.fit();
                this.term.write("\x1b[2K\r");
                this.term.clear();
              });
            }
          }
        });
    },
    deployLog(val, index) {
      if (val) {
        this.moduleId = val.id;
      }
      if (index) {
        this.deployLogPageNo = index;
      }
      let data = {
        pageNo: this.deployLogPageNo,
        pageSize: 10,
        moduleId: this.moduleId,
      };
      this.deployLogLoadingTable = true;
      api
        .logPage(data)
        .then((res) => {
          this.deployLogLoadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.deployLogTableDataSource = list;
            this.deployLogTableDataSource.forEach((item) => {
              this.$set(
                item,
                "commitInfo",
                item.commitInfo.replace(/\n/g, "<br/>")
              );
            });
            this.deployLogTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.deployLogChangePageItem(current),
            };
            this.deployLogVisible = true;
          }
        })
        .catch((err) => {
          this.deployLogLoadingTable = false;
        });
    },
    deployLogChangePageItem(index) {
      this.deployLog(null, index);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        projectName: this.projectName,
        moduleName: this.moduleName,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.desc {
  background-color: #f7f7f7;
  border-radius: 5px;
  .desc_header {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    overflow: auto;
  }
  .desc_content {
    padding: 10px 20px;
    .xml {
      font-size: 14px;
      background: transparent;
      margin: 0;
      code {
        font-family: Consolas, Menlo, Courier, monospace;
        .hljs-tag {
          color: #575757;
          span {
            color: #23b08a;
          }
        }
      }
    }
  }
}
</style>
