<template>
  <div class="statusTabList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目名称：
        <a-input
          placeholder="请输入项目名称:"
          v-model.trim="enName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select v-model="status" placeholder="请选择状态" allowClear>
          <a-select-option v-for="item in statusList" :key="item.value">{{
            item.key
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        环境：
        <a-select v-model="environmentId" placeholder="请选择环境" allowClear>
          <a-select-option v-for="item in environmentIdList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span
        slot="name"
        slot-scope="text, record"
        style="
          display: inline-block;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <span :title="text">{{ text }}</span>
      </span>
      <span slot="gitBranch" slot-scope="text, record" style="width: 100%">
        <a-icon type="branches" />{{ record.branch }}#{{
          record.commitId.substr(0, 8)
        }}
      </span>
      <span slot="status" slot-scope="text">
        <a-tag color="green" v-if="text == 'RUNNING'">运行中</a-tag>
        <a-tag color="red" v-if="text == 'STOPPED'">已停止</a-tag>
        <a-tag color="red" v-if="text == 'STOPPING'">停止中</a-tag>
        <a-tag color="orange" v-if="text == 'REBOOT'">重启中</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="order(record)">命令</a>
        <a-divider type="vertical" />
        <a-button
          type="link"
          style="padding: 0"
          @click="arthasDiagnosis(record)"
          :disabled="!record.arthasId"
          >arthas诊断</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" style="padding: 0" @click="flameGraph(record)"
          >火焰图</a-button
        >
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                title="确定要启动吗?"
                ok-text="是"
                cancel-text="否"
                @confirm="startClick(record)"
                :disabled="record.status !== 'STOPPED'"
              >
                <a-button
                  type="link"
                  style="padding: 0"
                  :disabled="record.status !== 'STOPPED'"
                  >启动</a-button
                >
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-button
                type="link"
                style="padding: 0"
                :disabled="record.status !== 'RUNNING'"
                @click="stopOrRestart(record, 'stop')"
                >停止</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-button
                type="link"
                style="padding: 0"
                :disabled="record.status !== 'RUNNING'"
                @click="stopOrRestart(record, 'restart')"
                >重启</a-button
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 命令 -->
    <a-modal
      title="命令"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="1000px"
    >
      <div>{{ commandAfterDelivery }}</div>
      <div style="margin-top: 10px">{{ commandBeforeDelivery }}</div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="submit" type="primary" @click="addKeyVisible = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/statusList.js";
import { ifCanDelivery } from "../lib/newRequest";
import { environmentList } from "../lib/errorLogList.js";
export default {
  name: "statusTabList",
  data() {
    return {
      environmentIdList: [],
      environmentId: undefined,
      status: undefined,
      addKeyVisible: false,
      commandAfterDelivery: "",
      commandBeforeDelivery: "",
      loadingTable: false,
      serverId: undefined,
      instanceList: [],
      statusList: [],
      enName: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          width: 150,
        },
        {
          title: "标题",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "环境",
          ellipsis: true,
          dataIndex: "environmentName",
          width: 130,
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "version",
          scopedSlots: { customRender: "gitBranch" },
          width: 150,
        },
        {
          title: "ip地址",
          ellipsis: true,
          dataIndex: "ip",
          width: 300,
        },
        {
          title: "pid",
          ellipsis: true,
          dataIndex: "pid",
          width: 100,
        },
        {
          title: "端口",
          ellipsis: true,
          dataIndex: "port",
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 150,
        },
        {
          title: "运行时长",
          ellipsis: true,
          dataIndex: "runningTime",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
    };
  },
  mounted() {
    this.query();
    this.getServerList();
    this.getStatus();
    this.getEnvironmentList();
  },
  methods: {
    // 查询环境
    getEnvironmentList() {
      environmentList().then((res) => {
        if (res.result === 200) {
          this.environmentIdList = res.data;
        }
      });
    },
    // 日志按钮
    logJump(val) {
      api.webShellAccount({ id: val.id }).then((res) => {
        if (res.result === 200) {
          let routeData = this.$router.resolve({
            path: "/terminal",
            query: {
              id: res.data.accountId,
              title: res.data.serverName,
              cmd: res.data.cmd,
            },
          });
          window.open(routeData.href, "_blank");
        }
      });
    },
    // 命令按钮
    order(val) {
      this.addKeyVisible = true;
      this.commandAfterDelivery = val.commandAfterDelivery;
      this.commandBeforeDelivery = val.commandBeforeDelivery;
    },
    // 关闭命令弹框
    addKeyClose() {},
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 查询状态下拉框
    getStatus() {
      api.statusInfo().then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        enName: this.enName,
        serverId: this.serverId,
        status: this.status,
        environmentId: this.environmentId,
      };
      this.getStatusList(data);
    },
    // 查询子项目列表
    getStatusList(data) {
      api.getStatus(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.dataSource = list;
          this.dataSource.forEach((item) => {
            this.$set(item, "stopVisible", false);
            this.$set(item, "restartVisible", false);
            this.$set(item, "name", item.title);
          });
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(index) {
      this.query(index);
    },
    // 启动项目
    startClick(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        serverId: val.serverId,
      };
      api.startProject(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("启动成功");
          this.query();
          this.loadingTable = false;
        }
      });
    },
    arthasDiagnosis(val) {
      window.open("http://arthas.huiyu.org.cn/?agentId=" + val.arthasId);
    },
    stopOrRestart(val, type) {
      ifCanDelivery({
        projectId: val.projectId,
        environmentId: val.environmentId,
      }).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.stopOrRestartSubmit(val, type);
          } else {
            this.$confirm({
              title: "确认发布",
              content: (h) => (
                <div style="color:red;">
                  现在为非常规操作时间, 强制操作造成的线上问题将由自己负责
                </div>
              ),
              okText: "确定",
              cancelText: "取消",
              onOk: () => {
                this.stopOrRestartSubmit(val, type);
              },
            });
          }
        }
      });
    },
    stopOrRestartSubmit(val, type) {
      this.$confirm({
        title: "确认操作",
        content: (h) => (
          <div style="color:red;">
            确认要{type == "stop" ? "停止" : "重启"}吗
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          if (type == "stop") {
            this.stopClick(val);
          } else {
            this.restartClick(val);
          }
        },
      });
    },
    flameGraph(val) {
      this.$router.push({
        path: "/flameGraph",
        query: { relationId: val.id, name: val.enName },
      });
    },
    // 停止项目
    stopClick(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        serverId: val.serverId,
      };
      api.stopProject(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("停止成功");
          this.loadingTable = false;
          this.query();
        }
      });
    },
    // 重启项目
    restartClick(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        serverId: val.serverId,
      };
      api.restartProject(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重启成功");
          this.loadingTable = false;
          this.query();
        }
      });
    },
  },
};
</script>
