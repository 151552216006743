<template>
  <div ref="flameGraph" class="flameGraph">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left"></icon-font>
        {{ name }}
      </span>
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        快照：
        <a-select
          v-model="jvmInfoId"
          placeholder="请选择快照"
          showSearch
          @search="getJvmInfoSnapshotList"
          @change="getJvmInfo()"
        >
          <a-select-option
            v-for="item in jvmInfoIdList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="save">保存快照</a-button>
        <a-button type="primary" @click="getJvmInfo" icon="reload"
          >刷新</a-button
        >
      </div>
    </div>
    <h1 style="font-size: 16px">火焰图</h1>
    <div id="chart"></div>
    <div id="myChart1" class="myChart" style="margin-top: 50px">
      <div id="myChart1-1"></div>
      <div id="myChart1-2"></div>
    </div>
    <div class="GC">
      <div class="gcColumn">
        <h1>总GC数据</h1>
        <div class="table">
          <div class="row">
            <div class="cell column">数量</div>
            <div class="cell">{{ gcInfo.totalGcCount }}</div>
          </div>
          <div class="row">
            <div class="cell column">时间</div>
            <div class="cell">{{ gcInfo.totalGcTime }}ms</div>
          </div>
          <div class="row">
            <div class="cell column">平均时间</div>
            <div class="cell">{{ gcInfo.totalGcAvgTime }}ms</div>
          </div>
        </div>
      </div>
      <div class="gcColumn">
        <h1>FULL GC数据</h1>
        <div class="table">
          <div class="row">
            <div class="cell column">数量</div>
            <div class="cell">{{ gcInfo.fullGcCount }}</div>
          </div>
          <div class="row">
            <div class="cell column">时间</div>
            <div class="cell">{{ gcInfo.fullGcTime }}ms</div>
          </div>
          <div class="row">
            <div class="cell column">平均时间</div>
            <div class="cell">{{ gcInfo.fullGcAvgTime }}ms</div>
          </div>
        </div>
      </div>
      <div class="gcColumn">
        <h1>MINOR GC数据</h1>
        <div class="table">
          <div class="row">
            <div class="cell column">数量</div>
            <div class="cell">{{ gcInfo.minorGcCount }}</div>
          </div>
          <div class="row">
            <div class="cell column">时间</div>
            <div class="cell">{{ gcInfo.minorGcTime }}ms</div>
          </div>
          <div class="row">
            <div class="cell column">平均时间</div>
            <div class="cell">{{ gcInfo.minorGcAvgTime }}ms</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="searchCondition">
        <div class="searchConditionItem">
          开始时间：
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="startTime"
            placeholder="请选择时间"
          />
        </div>
        <div class="searchConditionItem">
          结束时间：
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="endTime"
            placeholder="请选择时间"
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="reloadGcDetail">加载</a-button>
        </div>
      </div>
      <a-tabs
        type="card"
        v-model="active"
        @change="myChartInit"
        style="width: 100%"
      >
        <a-tab-pane key="1" tab="GC前堆内存"> </a-tab-pane>
        <a-tab-pane key="2" tab="GC后堆内存"> </a-tab-pane>
        <a-tab-pane key="3" tab="GC持续"> </a-tab-pane>
      </a-tabs>
    </div>
    <div id="myChart4" style="width: 100%; height: 500px"></div>
    <div id="myChart2" class="myChart"></div>
    <div id="myChart3" class="myChart">
      <div id="myChart3-1"></div>
      <div id="myChart3-2"></div>
    </div>
    <a-modal
      title="保存快照"
      v-model="visible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入名称" v-model="nameAdd" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visible = false">关闭</a-button>
          <a-button type="primary" @click="save_submit">确认</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/flameGraph";
import moment from "moment";
import * as echarts from "echarts";
require("echarts/lib/chart/bar");
export default {
  data() {
    return {
      relationId: 1,
      name: "",
      nameAdd: "",
      jvmInfoId: undefined,
      jvmInfoIdList: [],
      visible: false,
      jvmInfo: {},
      gcInfo: {
        totalGcCount: 0,
        totalGcTime: 0,
        totalGcAvgTime: 0,
        fullGcCount: 0,
        fullGcTime: 0,
        fullGcAvgTime: 0,
        minorGcCount: 0,
        minorGcTime: 0,
        minorGcAvgTime: 0,
      },
      myChart: null,
      myChart1_1: null,
      myChart1_2: null,
      myChart2: null,
      myChart3_1: null,
      myChart3_2: null,
      active: "1",
      myChartData: null,
      startTime: "",
      endTime: "",
    };
  },
  mounted() {
    let name = this.$route.query.name;
    let relationId = this.$route.query.relationId;
    if (name && relationId) {
      this.name = name;
      this.relationId = relationId;
    }
    this.startTime = this.$common.transformTime(moment().subtract(15, "m"));
    this.endTime = this.$common.transformTime(moment());
    this.getJvmInfoSnapshotList("", 1);
    this.reloadGcDetail();
  },
  methods: {
    reloadGcDetail() {
      let data = {
        relationId: this.relationId,
        jvmInfoId: this.jvmInfoId,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      api.reloadGcDetail(data).then((res) => {
        if (res.result == 200) {
          this.myChartData = res.data.gcDetail;
          this.myChartInit(this.active);
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    save() {
      let time = moment().format("YYYY-MM-DD HH:mm");
      this.nameAdd = this.name + "_" + time;
      this.visible = true;
    },
    save_submit() {
      let data = {
        jvmInfo: this.jvmInfo,
        relationId: this.relationId,
        name: this.nameAdd,
      };
      api.saveJvmInfoSnapshot(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("保存成功");
          this.visible = false;
          this.getJvmInfoSnapshotList();
        }
      });
    },
    getJvmInfoSnapshotList(name, index) {
      api
        .jvmInfoSnapshotList({ relationId: this.relationId, name })
        .then((res) => {
          if (res.result == 200) {
            this.jvmInfoIdList = res.data;
            this.jvmInfoId = res.data[0].id;
            if (index == 1) {
              this.getJvmInfo();
            }
          }
        });
    },
    getJvmInfo() {
      api
        .jvmInfo({
          relationId: this.relationId,
          jvmInfoId: this.jvmInfoId,
        })
        .then((res) => {
          if (res.result == 200) {
            this.jvmInfo = res.data;
            this.init(res.data.flameGraph);
            this.gcInit(res.data.gcInfo);
            this.memoryInit(res.data.memoryInfo);
            this.threadInit(res.data.threadInfo);
          }
        });
    },
    myChartInit(key) {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = echarts.init(document.getElementById("myChart4"));

      let option = {
        title: {
          text: "",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return (
              params[0].data.otherInfo +
              "<br>Heap Size: <b>" +
              params[0].data.value +
              "mb</b>," +
              params[0].name
            );
          },
        },
        xAxis: {
          type: "category",
          data: this.myChartData.time,
        },
        yAxis: {
          type: "value",
          name: "mb",
        },
        grid: {
          x: 50,
          y: 50,
          x2: 50,
          y2: 50,
        },
        series: {
          type: "line",
          data: [],
          areaStyle: {
            color: "#5bc8c1",
          },
          symbol: (data, params) => {
            if (params.data.otherInfo == "Young GC") {
              return "rect";
            } else {
              return "triangle";
            }
          },
          symbolSize: "10",
          showAllSymbol: true,
          itemStyle: {
            color: (params) => {
              if (params.data.otherInfo == "Young GC") {
                return "#5bc8c1";
              } else {
                return "red";
              }
            },
          },
          lineStyle: {
            color: "#5bc8c1",
          },
        },
      };
      if (key == "1") {
        let data = [];
        this.myChartData.memoryBeforeGc.forEach((element, index) => {
          data.push({
            otherInfo: this.myChartData.gcType[index],
            value: element,
          });
        });
        option.title.text = "GC前堆内存";
        option.series.data = data;
        this.myChart.setOption(option);
      } else if (key == "2") {
        let data = [];
        this.myChartData.memoryAfterGc.forEach((element, index) => {
          data.push({
            otherInfo: this.myChartData.gcType[index],
            value: element,
          });
        });
        option.title.text = "GC后堆内存";
        option.series.data = data;
        this.myChart.setOption(option);
      } else {
        let fullGcTimeConsumeList = [];
        let youngGcTimeConsumeList = [];

        this.myChartData.time.forEach((element, index) => {
          if (this.myChartData.fullGcTimeConsumeList[index] > 0) {
            fullGcTimeConsumeList.push([
              element,
              this.myChartData.fullGcTimeConsumeList[index],
            ]);
          }
          if (this.myChartData.youngGcTimeConsumeList[index] > 0) {
            youngGcTimeConsumeList.push([
              element,
              this.myChartData.youngGcTimeConsumeList[index],
            ]);
          }
        });
        option.title.text = "GC持续";
        option.tooltip = {};
        option.yAxis.name = "ms";
        option.legend = {
          orient: "vertical",
          left: "right",
        };
        option.series = [
          {
            name: "YONG GC",
            type: "scatter",
            data: youngGcTimeConsumeList,
            symbol: "rect",
            symbolSize: "14",
            color: "#5bc8c1",
          },
          {
            name: "FULL GC",
            type: "scatter",
            data: fullGcTimeConsumeList,
            symbol: "triangle",
            symbolSize: "14",
            color: "red",
          },
        ];
        this.myChart.setOption(option);
      }
    },
    gcInit(data) {
      data.minorGcAvgTime = (data.minorGcTime / data.minorGcCount).toFixed(2);
      data.fullGcAvgTime = (data.fullGcTime / data.fullGcCount).toFixed(2);
      data.totalGcAvgTime = (data.totalGcTime / data.totalGcCount).toFixed(2);
      this.gcInfo = data;
      if (this.myChart1_1) {
        this.myChart1_1.dispose();
        this.myChart1_1 = null;
      }
      if (this.myChart1_2) {
        this.myChart1_2.dispose();
        this.myChart1_2 = null;
      }
      this.myChart1_1 = echarts.init(document.getElementById("myChart1-1"));
      this.myChart1_1.setOption({
        title: {
          text: "GC累计时间",
          left: "center",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: {
          type: "pie",
          data: [
            {
              name: "FULL GC",
              value: data.fullGcTime,
            },
            {
              name: "MINOR GC",
              value: data.minorGcTime,
            },
          ],
        },
      });
      this.myChart1_2 = echarts.init(document.getElementById("myChart1-2"));
      this.myChart1_2.setOption({
        title: {
          text: "GC平均时间",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: ["FULL", "MINOR"],
        },
        yAxis: {
          type: "value",
        },
        series: {
          type: "bar",
          data: [data.fullGcAvgTime, data.minorGcAvgTime],
        },
      });
    },
    memoryInit(data) {
      if (this.myChart2) {
        this.myChart2.dispose();
        this.myChart2 = null;
      }
      this.myChart2 = echarts.init(document.getElementById("myChart2"));
      this.myChart2.setOption({
        title: {
          text: "内存",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["heapMax", "heapUsed", "nonHeapMax", "nonHeapUsed"],
        },
        xAxis: {
          type: "category",
          data: data.time,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          x: 50,
          y: 50,
          x2: 50,
          y2: 50,
        },
        series: [
          {
            name: "heapMax",
            type: "line",
            data: data.heapMax,
            showSymbol: false,
          },
          {
            name: "heapUsed",
            type: "line",
            data: data.heapUsed,
            showSymbol: false,
          },
          {
            name: "nonHeapMax",
            type: "line",
            data: data.nonHeapMax,
            showSymbol: false,
          },
          {
            name: "nonHeapUsed",
            type: "line",
            data: data.nonHeapUsed,
            showSymbol: false,
          },
        ],
      });
    },
    threadInit(data) {
      let nonDaemonThreadCount = data.threadCount - data.daemonThreadCount;
      let daemonThreadCount = data.daemonThreadCount;
      delete data.id;
      delete data.configId;
      delete data.gmtCreated;
      delete data.threadCount;
      delete data.daemonThreadCount;
      let info = [];
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          info.push({ name: key, value: element });
        }
      }
      if (this.myChart3_1) {
        this.myChart3_1.dispose();
        this.myChart3_1 = null;
      }
      if (this.myChart3_2) {
        this.myChart3_2.dispose();
        this.myChart3_2 = null;
      }
      this.myChart3_1 = echarts.init(document.getElementById("myChart3-1"));
      this.myChart3_1.setOption({
        title: {
          text: "thread state",
          left: "center",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: {
          type: "pie",
          data: info,
        },
      });

      this.myChart3_2 = echarts.init(document.getElementById("myChart3-2"));
      this.myChart3_2.setOption({
        title: {
          text: "daemon vs non-daemon",
          left: "center",
        },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: {
          type: "pie",
          radius: ["40%", "70%"],
          data: [
            {
              name: "daemon",
              value: daemonThreadCount,
            },
            {
              name: "non-daemon",
              value: nonDaemonThreadCount,
            },
          ],
        },
      });
    },
    init(data) {
      var clientWidth = this.$refs.flameGraph.clientWidth;
      let svgArr = document.getElementsByClassName("d3v4-flame-graph-tip");
      let tipArr = document.getElementsByClassName("d3v4-flame-graph");
      if (svgArr.length > 0) {
        for (let i = 0; i < svgArr.length; i++) {
          const element = svgArr[i];
          element.remove();
        }
      }
      if (tipArr.length > 0) {
        for (let i = 0; i < tipArr.length; i++) {
          const element = tipArr[i];
          element.remove();
        }
      }
      let flameGraph = d3v4
        .flameGraph()
        .width(clientWidth)
        .cellHeight(18)
        .transitionDuration(0)
        .transitionEase(d3v4.easeCubic)
        .sort(true)
        .title("");

      let tip = d3v4
        .tip()
        .direction("s")
        .offset([8, 0])
        .attr("class", "d3v4-flame-graph-tip")
        .html(function (d) {
          return d.data.name + ", thread count: " + d.data.value;
        });
      flameGraph.tooltip(tip);
      d3v4.select("#chart").datum(data).call(flameGraph);
      document.getElementById("HomeContent").scrollTop =
        document.getElementById("HomeContent").scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/d3.flameGraph.min.css";
.flameGraph {
  width: 100%;

  .back {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 500;
    padding-bottom: 10px;
  }
  #chart {
    padding: 20px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
  }
  .myChart {
    width: 100%;
    height: 500px;
    display: flex;
    flex-wrap: nowrap;
  }
  .GC {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 20px;
    .gcColumn {
      width: 33%;
      text-align: center;
      h1 {
        font-size: 16px;
      }
      .table {
        width: 100%;
        box-sizing: border-box;
        .row {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          box-sizing: border-box;
          justify-content: center;
          .cell {
            width: 30%;
            padding: 8px 10px;
            box-sizing: border-box;
            text-align: left;
            border: 1px solid #e1e1e1;
            border-bottom: none;
          }
          .column {
            box-sizing: border-box;
            border-right: none;
            color: white;
            background-color: #4c4f53;
          }
          &:nth-child(3) {
            .cell {
              border-bottom: 1px solid #e1e1e1;
            }
          }
        }
      }
    }
  }
  #myChart1,
  #myChart3 {
    div {
      width: 50%;
      height: 100%;
    }
  }
}
</style>
