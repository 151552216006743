<template>
  <div class="errorLogList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目名称：
        <a-input
          placeholder="请输入项目名称"
          v-model.trim="projectName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        Pod名称：
        <a-input
          placeholder="请输入Pod名称"
          v-model.trim="hostname"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
          style="width: 350px"
        />
      </div>
      <div class="searchConditionItem">
        日期：
        <a-date-picker
          :disabledDate="disabledLastLoginStartDate"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          v-model="startTime"
          placeholder="开始日期"
          @openChange="handleLastLoginStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledLastLoginEndDate"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="endTime"
          :open="loginEndOpen"
          @openChange="handleLastLoginEndOpenChange"
        />
      </div>
      <div class="searchConditionItem">
        内容：
        <a-input
          placeholder="请输入内容"
          v-model.trim="content"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      rowKey="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :loading="loadingTable"
      size="small"
    >
      <span slot="projectName" slot-scope="text, record" class="slotCell">
        <span>
          {{ record.projectName }}
        </span>
        <a-tooltip>
          <template slot="title"> 以该项目名称过滤 </template>
          <a-button
            type="link"
            size="small"
            icon="search"
            class="button"
            @click="projectNameSearchChange(record)"
          />
        </a-tooltip>
      </span>
      <span slot="hostname" slot-scope="text, record" class="slotCell">
        <a-tooltip>
          <template slot="title"> {{ record.ip }} </template>
          {{ record.hostname }}
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> 以该Pod名称过滤 </template>
          <a-button
            type="link"
            size="small"
            icon="search"
            class="button"
            @click="hostNameSearchChange(record)"
          />
        </a-tooltip>
      </span>
      <span
        slot="content"
        slot-scope="text, record"
        style="
          display: inline-block;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <span :title="text">{{ text }}</span>
      </span>
      <span slot="read" slot-scope="text">
        <a-tag color="green" v-if="text == true">已读</a-tag>
        <a-tag color="red" v-if="text == false">未读</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="detailClick(record)">详情</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.projectName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                title="确定?"
                ok-text="是"
                cancel-text="否"
                @confirm="readClick(record)"
                :disabled="record.read == true"
              >
                <a-button type="link" :disabled="record.read == true"
                  >已读</a-button
                >
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-button type="link" @click="distributionWorkTask(record)"
                >问题排查分配</a-button
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <a-button
      type="danger"
      :disabled="selectedRowKeys.length < 1"
      @click="batchDelete"
      icon="delete"
      >批量删除</a-button
    >
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="1200px"
    >
      <codemirror
        v-if="addKeyVisible"
        ref="myCode"
        v-model="detailValue"
        :options="temOptions"
        class="code_style"
      >
      </codemirror>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="新建问题排查分配"
      v-model="visible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="执行者"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-select
            mode="multiple"
            v-model="executorList"
            style="width: 80%"
            :filterOption="false"
            showSearch
            @search="getUserList"
            placeholder="请输入关键词"
          >
            <a-select-option v-for="i in userSearchList" :key="i.loginName">{{
              i.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="distributionWorkTask_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/errorLogList.js";
import { userList } from "../lib/projectList.js";
import moment from "moment";

export default {
  name: "errorLogList",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      selectedRowKeys: [], //表格多选框
      projectName: "", //项目名称
      hostname: "",
      content: "",
      errorLogId: "",
      title: "",
      detailValue: "",
      value: "",
      content: "",
      startTime: moment().format("YYYY-MM-DD"),
      endTime: moment().format("YYYY-MM-DD"),
      loginEndOpen: false,
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "项目名称",
          ellipsis: true,
          dataIndex: "projectName",
          scopedSlots: { customRender: "projectName" },
          width: 200,
        },
        {
          title: "Pod名称",
          ellipsis: true,
          dataIndex: "hostname",
          scopedSlots: { customRender: "hostname" },
          width: 350,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "次数",
          width: 50,
          ellipsis: true,
          dataIndex: "repeatCount",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "read",
          scopedSlots: { customRender: "read" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "gmtModified",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      questionId: "",
      visible: false,
      userSearchList: [],
      executorList: [],
      errorId: "",
    };
  },
  mounted() {
    let errorId = this.$route.query.errorId;
    let date = this.$route.query.date;
    if (errorId) {
      this.errorId = errorId;
    }
    if (date) {
      this.startTime = moment(date).format("YYYY-MM-DD");
      this.endTime = moment(date).format("YYYY-MM-DD");
    }
    this.query(1);
  },
  methods: {
    distributionWorkTask_submit() {
      let data = {
        id: this.questionId,
        executorList: this.executorList,
      };
      api.distributionWorkTask(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("分配成功");
          this.visible = false;
          this.query();
        }
      });
    },
    getUserList(name) {
      userList({ name }).then((res) => {
        if (res.result == 200) {
          this.userSearchList.splice(0);
          this.userSearchList.push(...res.data.userList);
        }
      });
    },
    distributionWorkTask(val) {
      this.questionId = val.id;
      this.visible = true;
    },
    // 表格选择
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    moment, // 引用moment方法
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    projectNameSearchChange(item) {
      this.projectName = item.projectName;
      this.query(1);
    },
    hostNameSearchChange(item) {
      this.hostname = item.hostname;
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        startTime: this.startTime ? this.startTime + " 00:00:00" : "",
        endTime: this.endTime ? this.endTime + " 23:59:59" : "",
        projectName: this.projectName,
        hostname: this.hostname,
        content: this.content,
        id: this.errorId,
      };
      this.loadingTable = true;
      api
        .errorLogList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "200"],
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
              onShowSizeChange: (current, pageSize) =>
                this.onShowSizeChange(current, pageSize),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pageSize = pageSize;
      this.query();
    },
    // 详情按钮
    detailClick(val) {
      this.title = val.projectName + "-详情";
      this.content = val.content;
      console.log(this.content);
      this.addKeyVisible = true;
      api.itemList({ errorLogId: val.id }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          for (var i = 0; i < list.length; i++) {
            this.value += list[i].content + "\n";
          }

          setTimeout(() => {
            this.detailValue = this.content + this.value;
          }, 500);
        }
      });
    },
    add_submit() {
      this.addKeyVisible = false;
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.value = "";
      this.detailValue = "";
      this.content = "";
    },
    // 不可选的登录日期
    disabledLastLoginStartDate(startValue) {
      const endValue = this.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledLastLoginEndDate(endValue) {
      const startValue = this.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择登录日期
    handleLastLoginStartOpenChange(open) {
      if (!open) {
        this.loginEndOpen = true;
      }
    },
    handleLastLoginEndOpenChange(open) {
      this.loginEndOpen = open;
    },
    // 删除错误日志
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteLog(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 批量删除
    batchDelete() {
      this.$confirm({
        title: "确认删除",
        content: (h) => (
          <div style="color:red;">确定要删除所选择的错误日志?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            idList: this.selectedRowKeys,
          };
          api.batchDeleteLog(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
              this.selectedRowKeys = [];
            }
          });
        },
        onCancel: () => {},
      });
    },
    readClick(val) {
      let data = {
        errorLogId: val.id,
      };
      api.read(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 14px !important;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.label-c-c-c {
  a {
    margin: 0 5px;
  }
}
</style>
