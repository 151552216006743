import http from "./axios";

// 查询项目状态列表
export const getStatus = ({
  environmentId,
  pageNo,
  pageSize,
  enName,
  serverId,
  status,
  queryAll,
}) => {
  return http.get("/delivery/project/statusList", {
    pageNo,
    pageSize,
    enName,
    serverId,
    status,
    environmentId,
    queryAll,
  });
};

// 启动项目
export const startProject = ({ id, serverId }) => {
  return http.get("/delivery/project/startProject", {
    id: id,
    serverId: serverId,
  });
};
// 停止项目
export const stopProject = ({ id, serverId }) => {
  return http.get("/delivery/project/stopProject", {
    id: id,
    serverId: serverId,
  });
};
// 重启项目
export const restartProject = ({ id, serverId }) => {
  return http.get("/delivery/project/restartProject", {
    id: id,
    serverId: serverId,
  });
};

// 查询服务器集合
export const serverList = ({ instanceName }) => {
  return http.get("/delivery/server/list?enabled=true", {
    instanceName: instanceName,
  });
};

// 获取账号及查看日志命令
export const webShellAccount = ({ id }) => {
  return http.get("/delivery/project/webShellAccount", {
    id: id,
  });
};

// 获取状态下拉框
export const statusInfo = () => {
  return http.get("/delivery/project/statusInfo", {});
};

// 查询运行操作日志
export const projectOperateLogPage = ({
  environmentId,
  pageNo,
  pageSize,
  enName,
  serverId,
}) => {
  return http.get("/delivery/project/projectOperateLogPage", {
    pageNo: pageNo,
    pageSize: pageSize,
    enName: enName,
    serverId: serverId,
    environmentId: environmentId,
  });
};
