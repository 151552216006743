<template>
  <div class="statusTabList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目名称：
        <a-input
          placeholder="请输入项目名称"
          v-model.trim="enName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        环境：
        <a-select v-model="environmentId" placeholder="请选择环境" allowClear>
          <a-select-option v-for="item in environmentIdList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
      :loading="loadingTable"
    >
      <span slot="operation" slot-scope="text">
        <span v-if="text == 'START'">启动 </span>
        <span v-if="text == 'STOP'">停止 </span>
        <span v-if="text == 'RESTART'">重启 </span>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/statusList.js";
import { environmentList } from "../lib/errorLogList.js";
export default {
  name: "statusTabList",
  data() {
    return {
      environmentIdList: [],
      environmentId: undefined,
      loadingTable: false,
      serverId: undefined,
      instanceList: [],
      enName: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "项目",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "ip",
        },
        {
          title: "环境",
          ellipsis: true,
          dataIndex: "environmentName",
        },
        {
          title: "操作行为",
          ellipsis: true,
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
        {
          title: "操作人",
          ellipsis: true,
          dataIndex: "operatorName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
    };
  },
  mounted() {
    this.query();
    this.getServerList();
    this.getEnvironmentList();
  },
  methods: {
    // 查询环境
    getEnvironmentList() {
      environmentList().then((res) => {
        if (res.result === 200) {
          this.environmentIdList = res.data;
        }
      });
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        enName: this.enName,
        serverId: this.serverId,
        environmentId: this.environmentId,
      };
      this.projectOperateLogPage(data);
    },
    // 查询子项目列表
    projectOperateLogPage(data) {
      api.projectOperateLogPage(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(current) {
      this.pageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        enName: this.enName,
        serverId: this.serverId,
      };
      this.projectOperateLogPage(data);
    },
  },
};
</script>
