<template>
  <div class="template">
    <a-tabs v-model="active" @change="callback" type="card">
      <a-tab-pane key="personage" tab="我的模板">
        <div class="searchCondition">
          <div class="searchConditionItem">
            名称：
            <a-input
              placeholder="请输入名称"
              v-model.trim="name"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            类别：
            <a-select
              v-model="category"
              placeholder="请选择类别"
              class="select-tag"
              allowClear
            >
              <a-select-option
                v-for="i in categoryList"
                :key="i.key"
                :value="i.key"
                >{{ i.value }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchConditionItem">
            分组：
            <a-select
              v-model="group"
              placeholder="请选择分组"
              class="select-tag"
              allowClear
            >
              <a-select-option
                v-for="i in groupSelectList"
                :key="i.group"
                :value="i.group"
                >{{ i.group }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addClick" icon="plus"
              >新增</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableDataSource"
          :pagination="tablePagination"
          :loading="loadingTable"
          size="small"
          :rowKey="(record) => record.id"
        >
          <span slot="enabled" slot-scope="text, record" style="width: 100%">
            <a-switch
              checked-children="已启用"
              un-checked-children="已禁用"
              v-model="record.enabled"
              @change="modifyClick(record, true)"
            />
          </span>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyContentClick(record)"
              >修改内容</a
            >
            <a-divider type="vertical" />
            <a href="javascript:;" @click="modifyClick(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              placement="right"
              okText="是"
              cancelText="否"
              v-if="record.deletable"
              @confirm="deleteClick(record)"
            >
              <template slot="title">确定要删除【{{ record.name }}】?</template>
              <a href="javascript:;" style="color: #ff4d4f">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="system" tab="系统模板">
        <div class="searchCondition">
          <div class="searchConditionItem">
            名称：
            <a-input
              placeholder="请输入名称"
              v-model.trim="name"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchConditionItem">
            类别：
            <a-select
              v-model="category"
              placeholder="请选择类别"
              class="select-tag"
              allowClear
            >
              <a-select-option
                v-for="i in categoryList"
                :key="i.key"
                :value="i.key"
                >{{ i.value }}</a-select-option
              >
            </a-select>
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableDataSource"
          :pagination="tablePagination"
          :loading="loadingTable"
          size="small"
          :rowKey="(record) => record.id"
        >
          <span slot="enabled" slot-scope="text" style="width: 100%">
            <a-tag v-if="text" color="green"> 已启用 </a-tag>
            <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyContentClick(record)"
              >修改内容</a
            >
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="类别:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="categoryAdd"
            placeholder="请选择类别"
            class="select-tag"
            allowClear
            @change="categoryChange"
          >
            <a-select-option
              v-for="i in categoryList"
              :key="i.key"
              :value="i.key"
              >{{ i.value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="系统模板:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="templateIdAdd"
            placeholder="请选择系统模板"
            class="select-tag"
            allowClear
            showSearch
            @change="templateChange"
          >
            <a-select-option
              v-for="i in templateIdList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="模板名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="nameAdd"
            placeholder="请输入模板名称"
            style="width: 70%"
          />
        </a-form-item>
        <a-form-item
          label="分组:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="groupAdd"
            placeholder="请选择分组"
            class="select-tag"
            allowClear
            showSearch
            v-if="groupShow"
          >
            <a-select-option
              v-for="i in groupSelectList"
              :key="i.group"
              :value="i.group"
              >{{ i.group }}</a-select-option
            >
          </a-select>
          <a-input
            v-model="groupAdd"
            placeholder="请输入分组名称"
            v-else
            style="width: 70%"
          />
          <a-button type="link" @click="groupShow = false" v-show="groupShow"
            >手动输入</a-button
          >
          <a-button type="link" @click="groupShow = true" v-show="!groupShow"
            >选择分组</a-button
          >
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改内容 -->
    <a-modal
      :title="contentTitle"
      v-model="contentVisible"
      :maskClosable="false"
      :afterClose="contentClose"
      width="1000px"
    >
      <div>
        <codemirror v-model="content" :options="temOptions" class="code_style">
        </codemirror>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="contentVisible = false">关闭</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="content_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/template.js";
import { groupList } from "../lib/templateCode.js";
import { codemirror } from "vue-codemirror";

export default {
  name: "templated",
  components: {
    codemirror,
  },
  data() {
    return {
      active: "personage",
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      title: "",
      name: "",
      nameAdd: "",
      content: "",
      type: undefined,
      // remark: "",
      templateIdAdd: undefined,
      categoryAdd: undefined,
      enabled: true,
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "类别",
          ellipsis: true,
          dataIndex: "category",
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "group",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      contentTitle: "",
      contentVisible: false,
      contentId: "",
      templateList: [],
      templateIdList: [],
      category: undefined,
      categoryList: [],
      groupAdd: undefined,
      group: undefined,
      groupSelectList: [],
      groupShow: true,
    };
  },
  mounted() {
    this.query(1);
    this.getCodeTemplateCategoryList();
    this.getGroupList();
  },
  methods: {
    getGroupList() {
      groupList().then((res) => {
        if (res.result == 200) {
          this.groupSelectList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        category: this.category,
        group: this.group,
      };
      if (this.active == "system") {
        data.isSystemTemplate = true;
      } else {
        data.isSystemTemplate = false;
      }
      this.codeTemplatePageList(data);
    },
    // 查询列表
    codeTemplatePageList(data) {
      this.loadingTable = true;
      api
        .codeTemplatePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val, enable) {
      this.title = "修改";
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.templateIdAdd = val.templateId;
      this.categoryAdd = val.category;
      this.enabled = val.enabled;
      if (enable) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.templateIdAdd = undefined;
      this.categoryAdd = undefined;
      this.enabled = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        category: this.categoryAdd,
        templateId: this.templateIdAdd,
        enabled: this.enabled,
        group: this.groupAdd,
      };
      if (this.title === "新增") {
        api.addCodeTemplate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editCodeTemplate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.nameAdd = "";
      this.templateIdAdd = undefined;
      this.categoryAdd = undefined;
      this.enabled = true;
      this.groupId = "";
      this.groupAdd = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCodeTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 修改内容
    modifyContentClick(val) {
      this.contentVisible = true;
      this.contentTitle = "【" + val.name + "】内容修改";
      this.contentId = val.id;
      this.content = val.content;
    },
    contentClose() {
      this.content = "";
    },
    content_submit() {
      let data = {
        id: this.contentId,
        content: this.content,
      };
      api.editCodeTemplateContent(data).then((res) => {
        if (res.result === 200) {
          this.contentVisible = false;
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    // 获取模板集合
    getCodeTemplateList(val) {
      let data = {
        name: "",
        isSystemTemplate: true,
        category: val,
      };
      api.codeTemplateList(data).then((res) => {
        if (res.result === 200) {
          this.templateIdList = res.data;
        }
      });
    },
    callback() {
      this.query(1);
    },
    templateChange(val) {
      this.nameAdd = this.templateIdList.filter((el) => el.id == val)[0].name;
    },
    // 查询类别
    getCodeTemplateCategoryList() {
      api.codeTemplateCategoryList().then((res) => {
        if (res.result === 200) {
          this.categoryList = res.data;
        }
      });
    },
    categoryChange(val) {
      this.getCodeTemplateList(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-tag {
  width: 70%;
}
</style>
