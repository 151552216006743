<template>
  <div class="ingressAndService">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="路由">
        <router-view :namespace="namespace" name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="服务">
        <router-view :namespace="namespace" name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  props: ["namespace"],
  name: "ingressAndService",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/ingressAndService/ingressList") {
          this.active = "1";
        } else if (newVal == "/ingressAndService/serviceList") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/ingressAndService/ingressList") return;
          this.$router.replace("/ingressAndService/ingressList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/ingressAndService/serviceList") return;
          this.$router.replace("/ingressAndService/serviceList");
        }
      },
    },
  },
};
</script>
