<template>
  <div class="nginxInstance">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          showSearch
          placeholder="请选择服务器"
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
          @change="query(1)"
          style="width: 240px"
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modifyClick(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="fileListShow(record)">文件列表</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title == '修改'"
        >
          <a-input
            v-model="nameAdd"
            placeholder="请输入名称"
            style="width: 240px"
          />
        </a-form-item>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title == '新增'"
        >
          <a-select
            v-model="serverIdAdd"
            showSearch
            placeholder="请选择服务器"
            :filter-option="false"
            @search="instanceIdSearch"
            allowClear
            style="width: 240px"
          >
            <a-select-option v-for="item in instanceList" :key="item.id">{{
              item.instanceName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="启用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title == '修改'"
        >
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="enabledAdd"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-drawer
      title="文件列表"
      placement="right"
      :visible="visible"
      @close="visible = false"
      width="1000"
    >
      <file-list :nginxInstanceId="nginxInstanceId"></file-list>
    </a-drawer>
  </div>
</template>
<script>
import api from "../lib/nginxInstance.js";
import { serverList } from "../lib/trustList.js";
import fileList from "./fileList.vue";

export default {
  name: "nginxInstance",
  components: { fileList },
  data() {
    return {
      title: "",
      name: "",
      serverId: undefined,
      serverIdAdd: undefined,
      enabledAdd: undefined,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "外网ip",
          ellipsis: true,
          dataIndex: "publicIp",
        },
        {
          title: "内网ip",
          ellipsis: true,
          dataIndex: "privateIp",
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "version",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      instanceList: [],
      visible: false,
      nginxInstanceId: "",
    };
  },
  mounted() {
    this.query(1);
    this.getServerList();
  },
  methods: {
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        serverId: this.serverId,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    fileListShow(val) {
      this.visible = true;
      this.nginxInstanceId = val.id;
    },
    // 修改
    modifyClick(val, enable) {
      this.title = "修改";
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.enabledAdd = val.enabled;
      if (enable) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.serverIdAdd = undefined;
    },
    // 确定新增
    add_submit() {
      if (this.title === "新增") {
        let data = {
          serverId: this.serverIdAdd,
        };
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        let data = {
          name: this.nameAdd,
          enabled: this.enabledAdd,
          id: this.groupId,
        };
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.serverIdAdd = undefined;
      this.getServerList();
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 获取enabled集合
    getenabledList() {
      let data = {
        param: "",
      };
      api.enabledList(data).then((res) => {
        if (res.result === 200) {
          this.enabledList = res.data;
        }
      });
    },
  },
};
</script>
