<template>
  <div class="ansibleTask">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="ansibleName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select v-model="status" placeholder="请选择状态" allowClear>
          <a-select-option v-for="item in statusList" :key="item.value">{{
            item.key
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="status" slot-scope="text">
        <a-tag color="green" v-if="text == 'SUCCESS'">成功 </a-tag>
        <a-tag color="red" v-if="text == 'ERROR'">失败</a-tag>
        <a-tag color="orange" v-if="text == 'RUNNING'"
          >运行中
          <a-icon type="loading" />
        </a-tag>
        <a-tag color="orange" v-if="text == 'WAITING'">等待执行</a-tag>
      </span>
      <span slot="serverCount" slot-scope="text, record" style="width: 100%">
        <a-popover title="服务器" trigger="click" placement="right">
          <span slot="content">
            <div style="max-width: 500px">
              <a-tag
                color="#108ee9"
                v-for="item in record.instanceNameList"
                :key="item"
                >{{ item }}</a-tag
              >
            </div>
          </span>
          <a href="javascript:;">{{ text }}</a>
        </a-popover>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          title="确定要立即执行吗?"
          ok-text="是"
          cancel-text="否"
          @confirm="startTask(record)"
        >
          <a :disabled="record.status !== 'WAITING'" href="javascript:;"
            >立即执行</a
          >
        </a-popconfirm>
        <a-divider type="vertical" />
        <a
          :disabled="record.status == 'WAITING'"
          href="javascript:;"
          @click="detailClick(record)"
          >详情</a
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.ansibleName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="1200px"
    >
      <codemirror
        v-if="addKeyVisible"
        ref="myCode"
        v-model="detailValue"
        :options="temOptions"
        class="code_style"
      >
      </codemirror>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ansibleTask.js";
import { serverList } from "../lib/statusList.js";
import { getPage } from "../lib/ansiblePlaybook.js";

export default {
  name: "ansibleTask",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      timer: null,
      timerSearch: null,
      infoId: undefined,
      title: "",
      ansibleName: "",
      ansibleNameAdd: "",
      path: "",
      ansibleId: "",
      status: undefined,
      statusList: [],
      instanceList: [],
      serverIdList: [],
      ansibleId: undefined,
      ansibleList: [],
      detailValue: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "playbook名称",
          ellipsis: true,
          dataIndex: "ansibleName",
        },
        {
          title: "服务器数量",
          ellipsis: true,
          dataIndex: "serverCount",
          scopedSlots: { customRender: "serverCount" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
    this.getStatusList();
    this.timerSearch = setInterval(() => {
      this.query();
    }, 2000);
  },
  methods: {
    // 获取状态
    getStatusList() {
      api.statusList().then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ansibleName: this.ansibleName,
        status: this.status,
      };
      this.getPageList(data);
    },
    // 查询分页列表
    getPageList(data) {
      this.loadingTable = true;
      api
        .logPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              let count = 0;
              if (item.serverIdList) {
                count = item.serverIdList.length;
              }
              item.serverCount = count + " 台";
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteAnsibleTask(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 服务器下拉框发生变化
    serverIdSarch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // ansibleIdSarch
    ansibleIdSarch(name) {
      this.getAnsibleList(name);
    },
    // 查询ansible
    getAnsibleList(name) {
      let data = {
        name: name,
        pageNo: 1,
        pageSize: 100,
      };
      getPage(data).then((res) => {
        if (res.result === 200) {
          this.ansibleList = res.data.records;
        }
      });
    },
    // 立即执行
    startTask(val) {
      let data = {
        id: val.id,
      };
      api.startAnsibleTask(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("执行成功");
          this.query(1);
        }
      });
    },
    // 获取详情
    getInfo() {
      api.taskInfo({ id: this.infoId }).then((res) => {
        if (res.result === 200) {
          setTimeout(() => {
            this.detailValue = res.data.content;
          }, 500);
          if (res.data.status !== "RUNNING" && this.timer) {
            clearInterval(this.timer);
          }
        }
      });
    },

    // 点击详情
    detailClick(val) {
      this.addKeyVisible = true;
      this.title = val.ansibleName + "-详情";
      this.infoId = val.id;
      this.getInfo();
      if (val.status == "RUNNING") {
        this.timer = setInterval(() => {
          this.getInfo();
        }, 1000);
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.value = "";
      this.detailValue = "";
      this.content = "";
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭定时器
    }
    if (this.timerSearch) {
      clearInterval(this.timerSearch);
    }
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 14px !important;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped></style>
