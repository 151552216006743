<template>
  <div class="workbench">
    <div class="request-search-button">
      <a-button type="link" @click="add()" style="padding: 0">
        <a-icon type="plus"></a-icon>
        创建需求
      </a-button>
      <div
        class="screen"
        style="margin-left: auto"
        @click.stop="visible = true"
      >
        项目成员
        <icon-font type="icon-chengyuan"></icon-font>
      </div>
      <div
        :class="{ screen: true, screenHover: screenPanelShow }"
        @click.stop="screenPanelShow = !screenPanelShow"
      >
        筛选<a-icon type="down" style="font-size: 12px; margin-left: 5px" />
      </div>
      <div class="screenPanel" v-show="screenPanelShow" @click.stop="stop">
        <a-input
          placeholder="搜索任务"
          style="margin: 0 25px; width: calc(100% - 50px)"
          v-model="screen.title"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-form style="margin-top: 20px; padding: 0 15px">
          <a-form-item
            label="执行者:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-select
              v-model="screen.executors"
              placeholder="请选择执行者"
              mode="multiple"
              class="select-tag"
              allowClear
              :filter-option="false"
              @search="getProjectMemberList"
              @blur="getProjectMemberList()"
              showSearch
            >
              <a-select-option
                v-for="item in projectMemberList"
                :key="item.userId"
                :value="item.loginName"
              >
                <a-avatar :size="20" :src="item.avatar" />
                {{ item.userName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="参与者:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-select
              v-model="screen.participants"
              placeholder="请选择参与者"
              mode="multiple"
              class="select-tag"
              allowClear
              :filter-option="false"
              @search="getProjectMemberList"
              @blur="getProjectMemberList()"
              showSearch
            >
              <a-select-option
                v-for="item in projectMemberList"
                :key="item.userId"
                :value="item.loginName"
              >
                <a-avatar :size="20" :src="item.avatar" />
                {{ item.userName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <template v-if="type !== 'INVENTORY_TASK'">
            <a-form-item
              label="截止时间:"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }"
            >
              <div>
                <a-date-picker
                  format="YYYY-MM-DD"
                  v-model="screen.endDateStart"
                  style="width: 120px"
                />
                -
                <a-date-picker
                  format="YYYY-MM-DD"
                  v-model="screen.endDateEnd"
                  style="width: 120px"
                />
              </div>
            </a-form-item>
            <a-form-item
              label="是否完成:"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-radio-group
                :options="finishOptions"
                v-model="screen.finished"
              />
            </a-form-item>
            <a-form-item
              label="优先级:"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }"
              class="priority"
            >
              <a-checkbox-group
                :options="priorityList"
                v-model="screen.prioritys"
              />
            </a-form-item>
          </template>
        </a-form>
        <div style="border-top: 1px solid #e5e5e5; padding: 10px 15px">
          <a-button type="link" style="padding: 0" @click="reset">
            <a-icon type="redo" />
            重置
          </a-button>
        </div>
      </div>
    </div>
    <div class="tableRow">
      <div class="tableHead">
        <template v-if="idList.length == 0">
          <template v-for="(item, index) in columns">
            <div
              class="row"
              v-if="item.workbenchTypeList.indexOf(type) !== -1"
              :style="{
                width: item.width,
                textAlign:
                  item.type !== 'title' && item.type !== 'executor'
                    ? 'center'
                    : 'left',
              }"
              @click="sort(item)"
            >
              <span
                :class="{
                  ml: item.type == 'title',
                }"
              >
                <a-checkbox
                  @change="onCheckAll"
                  v-if="item.type == 'title'"
                  @click.stop="stop"
                  v-model="checkAll"
                ></a-checkbox>
                {{
                  item.type !== "title" && item.type !== "executor"
                    ? ""
                    : "&nbsp;&nbsp;"
                }}
                {{ item.title }}
              </span>
              <a-icon v-show="item.show && item.top" type="caret-up"></a-icon>
              <a-icon
                v-show="item.show && !item.top"
                type="caret-down"
              ></a-icon>
              <div
                :class="{ borderRight: true, borderHover: item.borderHover }"
                @mouseenter="resizeHover($event, index, item)"
                @mouseleave="resizeHide(index, item)"
                @click.stop="stop"
              ></div>
            </div>
          </template>
        </template>
        <template v-else>
          <div style="border: none">
            <span class="ml">
              <a-checkbox
                @change="onCheckAll"
                @click.stop="stop"
                v-model="checkAll"
              ></a-checkbox>
              &nbsp;&nbsp; 已选择{{ idList.length }}项
            </span>
            <span class="ml">
              <a-button
                type="link"
                icon="delete"
                class="button"
                @click="deleteWork"
                style="color: red"
                >删除</a-button
              >
            </span>
            <span class="ml">
              <a-button
                type="link"
                icon="check"
                class="button"
                @click="markFinishedWork"
                style="color: #52c41a"
                >标记完成</a-button
              >
            </span>
            <span
              class="ml"
              v-if="type == 'GENERAL_TASK' || type == 'INVENTORY_TASK'"
            >
              <a-button
                v-if="type == 'GENERAL_TASK'"
                type="link"
                icon="arrow-right"
                class="button"
                @click="migrateTask"
              >
                迁移到任务库
              </a-button>
              <a-button
                v-else
                type="link"
                icon="arrow-left"
                class="button"
                @click="migrateTask"
              >
                迁移到当前任务
              </a-button>
            </span>
          </div>
        </template>
      </div>
    </div>
    <div class="tableRow" style="margin-top: 10px; cursor: pointer">
      <div class="tableHead" style="border-bottom: 1px solid #e5e5e5">
        <div style="width: 300px">
          <span class="ml">
            <a-button
              type="link"
              style="color: #8c8c8c; font-weight: 500; margin-left: 12px"
            >
              {{
                type == "SCHEDULE_TASK"
                  ? "周期需求"
                  : screen.finished == "no"
                  ? "未完成的需求"
                  : "已完成的需求"
              }}
            </a-button>
          </span>
        </div>
      </div>
      <div class="tableContent">
        <draggable v-model="tableDataSource" @end="end">
          <div v-for="(item, index) in tableDataSource" :key="item.id">
            <workbench-col
              :item="item"
              :index="index"
              :priorityList="priorityList"
              :stateList="stateList"
              :hoverIndex="hoverIndex"
              :borderHoverShow="borderHoverShow"
              :columns="columns"
              :projectMemberList="projectMemberList"
              :length="tableDataSource.length"
              :screen="screen"
              :type="type"
              @childTaskShow="childTaskShow"
              @edit="edit"
              @changeDate="changeDate"
              @onCheck="onCheck"
              @resizeHide="resizeHide"
              @resizeHover="resizeHover"
              @add="add"
              @getProjectMemberList="getProjectMemberList"
              @listAni="listAni"
              @stateChange="stateChange"
            ></workbench-col>
          </div>
        </draggable>
      </div>
      <div
        class="tableHead"
        style="border-top: 1px solid #e5e5e5"
        v-show="total > tableDataSource.length"
      >
        <div style="width: 300px" @click="loadMore">
          <span class="ml"> 加载更多··· </span>
        </div>
      </div>
    </div>
    <a-modal
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="700px"
      @cancel="addClose"
      @click.native="scheduleShow = false"
    >
      <div @click="executorListShow = false" style="padding: 24px">
        <h3>创建需求</h3>
        <a-textarea
          v-model="titleAdd"
          placeholder="请输入标题"
          class="title"
          ref="title"
          autosize
        />
        <div class="col">
          <div class="executorList" @click.stop="executorListShow = true">
            <template v-if="executorListAdd.length > 0">
              <div
                class="executor"
                v-for="(item, index) in executorListAdd"
                @mouseenter="item.iconShow = true"
                @mouseleave="item.iconShow = false"
              >
                <a-icon
                  type="close-circle"
                  class="icon_remove"
                  v-show="item.iconShow"
                  @click.stop="deleteExecutor(index, 'EXECUTOR')"
                />
                <a-avatar size="small" :src="item.avatar" />
                <span class="name" style="margin-left: 5px">{{
                  item.userName
                }}</span>
              </div>
            </template>
            <div class="executor" v-else>
              <span class="name">待设置</span>
            </div>
            <div class="nameList" v-show="executorListShow">
              <div class="search">
                <a-input
                  v-model="name"
                  placeholder="搜索"
                  ref="search"
                  @change="getProjectMemberList()"
                />
              </div>
              <div class="userlist">
                <template v-if="projectMemberList.length > 0">
                  <div
                    v-for="item in projectMemberList"
                    :key="item.userId"
                    class="userItem"
                    @click.stop="checkUser(item, 'EXECUTOR')"
                  >
                    <a-avatar :src="item.avatar" />
                    <span style="margin-left: 15px">{{ item.userName }}</span>
                  </div>
                </template>
                <a-empty v-else />
              </div>
            </div>
          </div>
          <div v-if="type !== 'INVENTORY_TASK'">
            <a-range-picker
              v-model="rangeTime"
              format="YYYY-MM-DD"
              :ranges="range"
              style="width: 250px"
            ></a-range-picker>
          </div>
          <div
            class="schedule"
            v-if="type == 'SCHEDULE_TASK'"
            @click.stop="stop"
          >
            <a-button
              type="link"
              class="scheduleButton"
              @click.stop="scheduleShow = !scheduleShow"
            >
              <a-icon
                type="history"
                :style="{
                  color: schedule.value.length > 0 ? '#1890ff' : '#8c8c8c',
                }"
              />
            </a-button>
            <div class="schedulePanel" v-show="scheduleShow">
              <div class="schedulePanelItem">
                重复:
                <a-select
                  style="width: 100px"
                  v-model="schedule.type"
                  @change="scheduleTypeChange"
                >
                  <a-select-option
                    v-for="item in scheduleTypeList"
                    :key="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="schedulePanelItem">
                间隔:
                <a-select style="width: 50px" v-model="schedule.interval">
                  <a-select-option v-for="(item, index) in 8" :key="index">{{
                    index
                  }}</a-select-option>
                </a-select>
                &nbsp;{{ schedule.type == "week" ? "周" : "月" }}
              </div>
              <div class="schedulePanelItem">
                <div class="dayList">
                  <template v-if="schedule.type == 'week'">
                    <div
                      :class="{ option: true, checkOption: item.show }"
                      v-for="(item, index) in weekDayList"
                      :key="item.value"
                      @click="checkDay(index)"
                    >
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :class="{ option: true, checkOption: item.show }"
                      v-for="(item, index) in monthDayList"
                      :key="item.value"
                      @click="checkDay(index)"
                    >
                      {{ item.value }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="schedulePanelItem">
                {{ schedule.type == "week" ? "每周" : "每月"
                }}{{ schedule.value.join(",")
                }}{{ schedule.type == "week" ? " " : " 日" }}重复
              </div>
              <div class="schedulePanelItem">
                <a-button
                  type="primary"
                  style="float: right"
                  @click="scheduleShow = false"
                  >确定</a-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-show="childrenShow && parentTaskTitle">
          <span
            style="
              font-size: 16px;
              margin-right: 10px;
              color: #8c8c8c;
              font-weight: 00;
            "
            >属于任务:</span
          >
          <span style="font-size: 16px; font-weight: 700">{{
            parentTaskTitle
          }}</span>
        </div>
        <div class="formItem">
          <div class="label">备注</div>
          <div class="value">
            <quill-editor
              ref="addQuillEditor"
              v-model="remarkAdd"
              :options="editorOption"
              v-if="addEditorShow"
            />
          </div>
        </div>
        <div class="formItem" v-if="type !== 'INVENTORY_TASK'">
          <div class="label">优先级</div>
          <div class="value">
            <a-select v-model="priorityAdd" class="priority">
              <a-select-option v-for="item in priorityList" :key="item.value">
                <a-tag
                  style="
                    cursor: pointer;
                    height: 28px;
                    line-height: 26px;
                    font-size: 14px;
                    text-align: center;
                    background-color: #ffffff;
                  "
                  :color="item.color"
                  >{{ item.label }}</a-tag
                >
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="formItem">
          <div class="label">附件</div>
          <div class="value">
            <a-upload
              name="file"
              :multiple="true"
              :showUploadList="false"
              :customRequest="customRequest"
            >
              <a-button type="link">
                <a-icon type="upload" /> 添加附件
              </a-button>
            </a-upload>
          </div>
        </div>
        <div class="fileList" v-if="fileList.length > 0">
          <div
            class="file"
            v-for="item in fileList"
            :key="item.id"
            @click="download(item.path)"
            @mouseenter="item.show = true"
            @mouseleave="item.show = false"
            title="点击下载"
          >
            <div class="bg">
              <icon-font
                type="icon-txt"
                style="font-size: 32px"
                v-if="item.type == 'txt'"
              ></icon-font>
              <icon-font
                type="icon-zip"
                style="font-size: 32px"
                v-else-if="item.type == 'zip'"
              ></icon-font>
              <icon-font
                type="icon-markdown"
                style="font-size: 32px"
                v-else-if="item.type == 'md'"
              ></icon-font>
              <icon-font
                type="icon-word"
                style="font-size: 32px"
                v-else-if="item.type == 'docx' || item.type == 'doc'"
              ></icon-font>
              <icon-font
                type="icon-xlsx"
                style="font-size: 32px"
                v-else-if="item.type == 'xlsx' || item.type == 'xls'"
              ></icon-font>
              <icon-font
                type="icon-html"
                style="font-size: 32px"
                v-else-if="item.type == 'html' || item.type == 'htm'"
              ></icon-font>
              <icon-font
                type="icon-js"
                style="font-size: 32px"
                v-else-if="item.type == 'js'"
              ></icon-font>
              <icon-font
                type="icon-pdf"
                style="font-size: 32px"
                v-else-if="item.type == 'pdf'"
              ></icon-font>
              <icon-font
                type="icon-other"
                style="font-size: 32px"
                v-else
              ></icon-font>
            </div>
            <div class="name" :title="item.name">{{ item.name }}</div>
            <span
              class="delete"
              v-show="item.show"
              @click.stop="deleteFile(item.id)"
            >
              <a-icon type="delete"></a-icon>
            </span>
          </div>
          <div
            class="spaceFile"
            v-for="item in 5 - (fileList.length % 5)"
          ></div>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: right">
          <a-button key="back" @click="add_submit(true)"
            >完成并创建下一个</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit(false)"
            >完成</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      v-model="editKeyVisible"
      class="action-class"
      @cancel="editClose"
      width="1200px"
      :footer="null"
      @click.native="scheduleShow = false"
    >
      <span slot="title">
        <span style="font-size: 18px">修改需求</span>
        <span
          style="margin-left: 10px; font-size: 14px; cursor: pointer"
          v-if="parentId && parent"
          @click="workTaskDetail(parent.id)"
        >
          <icon-font type="icon-arrow-left"></icon-font>
          返回上一级
        </span>
      </span>
      <div
        @click="(executorListShow = false), (participantListShow = false)"
        class="flex"
      >
        <div
          class="flexItem"
          style="border-right: 1px solid #e5e5e5; width: 60%"
        >
          <div style="padding: 24px 24px 0; border-bottom: 1px solid #e5e5e5">
            <a-textarea
              v-model="titleAdd"
              placeholder="请输入标题"
              class="editTitle"
              ref="title"
              @blur="updateTitle"
              autosize
            />
            <div class="col">
              <div class="executorList" @click.stop="executorListShow = true">
                <template v-if="executorListAdd.length > 0">
                  <template v-for="(item, index) in executorListAdd">
                    <div
                      class="executor"
                      @mouseenter="item.iconShow = true"
                      @mouseleave="item.iconShow = false"
                    >
                      <a-icon
                        type="close-circle"
                        class="icon_remove"
                        v-show="item.iconShow"
                        @click.stop="deleteExecutor(index, 'EXECUTOR')"
                      />
                      <a-avatar size="small" :src="item.avatar" />
                      <span class="name" style="margin-left: 5px">{{
                        item.userName
                      }}</span>
                    </div>
                  </template>
                </template>
                <div class="executor" v-else>
                  <span class="name">待设置</span>
                </div>
                <div class="nameList" v-show="executorListShow">
                  <div class="search">
                    <a-input
                      v-model="name"
                      placeholder="搜索"
                      ref="search"
                      @change="getProjectMemberList()"
                    />
                  </div>
                  <div class="userlist">
                    <template v-if="projectMemberList.length > 0">
                      <div
                        v-for="item in projectMemberList"
                        :key="item.userId"
                        class="userItem"
                        @click.stop="checkUser(item, 'EXECUTOR')"
                      >
                        <a-avatar :src="item.avatar" />
                        <span style="margin-left: 15px">{{
                          item.userName
                        }}</span>
                      </div>
                    </template>
                    <a-empty v-else />
                  </div>
                </div>
              </div>
              <div v-if="type !== 'INVENTORY_TASK'">
                <a-range-picker
                  v-model="rangeTime"
                  format="YYYY-MM-DD"
                  :ranges="range"
                  @change="dateChange"
                  style="width: 250px"
                ></a-range-picker>
              </div>
              <div
                class="schedule"
                v-if="type == 'SCHEDULE_TASK'"
                @click.stop="stop"
              >
                <a-button
                  type="link"
                  class="scheduleButton"
                  @click.stop="scheduleShow = !scheduleShow"
                >
                  <a-icon
                    type="history"
                    :style="{
                      color: schedule.value.length > 0 ? '#1890ff' : '#8c8c8c',
                    }"
                  />
                </a-button>
                <div class="schedulePanel" v-show="scheduleShow">
                  <div class="schedulePanelItem">
                    重复:
                    <a-select
                      style="width: 100px"
                      v-model="schedule.type"
                      @change="scheduleTypeChange"
                    >
                      <a-select-option
                        v-for="item in scheduleTypeList"
                        :key="item.value"
                        >{{ item.label }}</a-select-option
                      >
                    </a-select>
                  </div>
                  <div class="schedulePanelItem">
                    间隔:
                    <a-select style="width: 50px" v-model="schedule.interval">
                      <a-select-option v-for="item in 7" :key="item">{{
                        item
                      }}</a-select-option>
                    </a-select>
                    &nbsp;{{ schedule.type == "week" ? "周" : "月" }}
                  </div>
                  <div class="schedulePanelItem">
                    <div class="dayList">
                      <template v-if="schedule.type == 'week'">
                        <div
                          :class="{ option: true, checkOption: item.show }"
                          v-for="(item, index) in weekDayList"
                          :key="item.value"
                          @click="checkDay(index)"
                        >
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-else>
                        <div
                          :class="{ option: true, checkOption: item.show }"
                          v-for="(item, index) in monthDayList"
                          :key="item.value"
                          @click="checkDay(index)"
                        >
                          {{ item.value }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="schedulePanelItem">
                    {{ schedule.type == "week" ? "每周" : "每月"
                    }}{{ schedule.value.join(",")
                    }}{{ schedule.type == "week" ? " " : " 日" }}重复
                  </div>
                  <div class="schedulePanelItem">
                    <a-button
                      type="primary"
                      style="float: right"
                      @click="scheduleChange"
                      >确定</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll_y">
            <div class="formItem" style="padding: 0 24px">
              <div class="label" style="margin-top: 3px">备注</div>
              <div class="value" v-if="editKeyVisible">
                <quill-editor
                  ref="editQuillEditor"
                  v-model="remarkAdd"
                  :options="editorOption"
                  v-show="editorShow"
                />
                <div
                  v-if="editorShow"
                  style="
                    margin-top: 10px;
                    display: flex;
                    justify-content: right;
                  "
                >
                  <a-button key="back" @click="editorShow = false"
                    >取消</a-button
                  >
                  <a-button
                    type="primary"
                    @click="updateRemark"
                    style="margin-left: 10px"
                    >确定</a-button
                  >
                </div>
                <div
                  v-if="remarkAdd && !editorShow"
                  class="editorHide"
                  id="editorHide"
                  @click="imgClick"
                  ref="editorHide"
                >
                  <div class="ql-editor" v-html="remarkAdd"></div>
                </div>
                <div
                  v-show="!remarkAdd && !editorShow"
                  class="editorHide"
                  @click="editorShow = true"
                >
                  <p>待处理</p>
                </div>
              </div>
            </div>
            <div
              class="formItem"
              style="padding: 0 24px"
              v-if="type !== 'INVENTORY_TASK'"
            >
              <div class="label">状态</div>
              <div class="value">
                <a-select
                  v-model="stateAdd"
                  class="priority"
                  @change="stateChange(true)"
                >
                  <a-select-option
                    v-for="item in stateList"
                    :key="item.value"
                    v-if="item.show || isCreator"
                  >
                    <a-tag
                      style="
                        cursor: pointer;
                        height: 28px;
                        line-height: 26px;
                        font-size: 14px;
                        text-align: center;
                      "
                      :color="item.color"
                      >{{ item.label }}</a-tag
                    >
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div
              class="formItem"
              style="padding: 0 24px"
              v-if="type !== 'INVENTORY_TASK'"
            >
              <div class="label">优先级</div>
              <div class="value">
                <a-select
                  v-model="priorityAdd"
                  class="priority"
                  @change="priorityChange"
                >
                  <a-select-option
                    v-for="item in priorityList"
                    :key="item.value"
                  >
                    <a-tag
                      style="
                        cursor: pointer;
                        height: 28px;
                        line-height: 26px;
                        font-size: 14px;
                        text-align: center;
                        background-color: #ffffff;
                      "
                      :color="item.color"
                      >{{ item.label }}</a-tag
                    >
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="formItem" style="padding: 0 24px" v-if="!parentId">
              <div class="label">子任务</div>
              <div class="value"></div>
            </div>
            <div class="childrenTask" v-if="!parentId">
              <div class="child" v-for="(item, index) in childTaskList">
                <div class="select">
                  <a-select
                    v-model="item.state"
                    class="priority"
                    @change="stateChange(true, item.id, item.state)"
                    style="width: 90px"
                  >
                    <a-select-option
                      v-for="state in stateList"
                      :key="state.value"
                      v-if="state.show || item.isCreator"
                    >
                      <a-tag
                        style="
                          cursor: pointer;
                          height: 28px;
                          line-height: 26px;
                          font-size: 14px;
                          text-align: center;
                        "
                        :color="state.color"
                        >{{ state.label }}</a-tag
                      >
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  class="childTitle"
                  @click="edit(item)"
                  v-if="
                    index !== childTaskList.length - 1 || addChildrenTaskShow
                  "
                >
                  {{ item.title }}
                </div>
                <a-input
                  class="childTitle"
                  type="text"
                  v-else
                  v-model="item.title"
                />
                <div class="executorList" @click.stop="item.listShow = true">
                  <template v-if="item.executorList.length > 0">
                    <template
                      v-for="(executor, executorIndex) in item.executorList"
                    >
                      <div
                        class="executor"
                        @mouseenter="executor.iconShow = true"
                        @mouseleave="executor.iconShow = false"
                      >
                        <a-icon
                          type="close-circle"
                          class="icon_remove"
                          v-show="executor.iconShow"
                          @click.stop="
                            deleteChildExecutor(
                              index,
                              executorIndex,
                              'EXECUTOR'
                            )
                          "
                        />
                        <a-avatar :src="executor.avatar" />
                      </div>
                    </template>
                  </template>
                  <div class="executor" v-else>
                    <span class="name">待设置</span>
                  </div>
                  <div class="nameList" v-show="item.listShow">
                    <div class="search">
                      <a-input
                        v-model="name"
                        placeholder="搜索"
                        @change="getProjectMemberList()"
                      />
                    </div>
                    <div class="userlist">
                      <template v-if="projectMemberList.length > 0">
                        <div
                          v-for="projectMember in projectMemberList"
                          :key="projectMember.userId"
                          class="userItem"
                          @click.stop="
                            checkChildUser(projectMember, index, 'EXECUTOR')
                          "
                        >
                          <a-avatar :src="projectMember.avatar" />
                          <span style="margin-left: 15px">{{
                            projectMember.userName
                          }}</span>
                        </div>
                      </template>
                      <a-empty v-else />
                    </div>
                  </div>
                </div>
                <div style="margin-left: auto">
                  <a-range-picker
                    v-model="item.rangeDate"
                    format="YYYY-MM-DD"
                    :ranges="range"
                    @change="
                      () => {
                        if (item.id) {
                          updateStartEndTime(
                            $common.dayFormat(item.rangeDate[0]) + ' 00:00:00',
                            true,
                            item.id
                          );
                          updateStartEndTime(
                            $common.dayFormat(item.rangeDate[1]) + ' 23:59:59',
                            false,
                            item.id
                          );
                        }
                      }
                    "
                    style="width: 250px"
                  ></a-range-picker>
                </div>
              </div>
              <div
                class="child"
                style="color: #1b9aee; line-height: 37px; cursor: pointer"
                @click="addChildrenTask"
                v-show="addChildrenTaskShow"
              >
                <span style="line-height: 18px">
                  <a-icon type="plus" style="font-size: 16px; margin: 0 10px" />
                  添加子任务
                </span>
              </div>
              <div
                style="padding: 5px 10px; text-align: right"
                v-show="!addChildrenTaskShow"
              >
                <a-button key="back" @click="addChildrenTask_cancel"
                  >取消</a-button
                >
                <a-button
                  type="primary"
                  @click="addChildrenTask_submit"
                  style="margin-left: 10px"
                  >确定</a-button
                >
              </div>
            </div>
            <div class="formItem" style="padding: 0 24px">
              <div class="label">附件</div>
              <div class="value">
                <a-upload
                  name="file"
                  :multiple="true"
                  :showUploadList="false"
                  :customRequest="customRequest"
                >
                  <a-button type="link">
                    <a-icon type="upload" /> 添加附件
                  </a-button>
                </a-upload>
              </div>
            </div>
            <div
              class="fileList"
              v-if="fileList.length > 0"
              style="margin: 0 34px"
            >
              <div
                class="file"
                v-for="item in fileList"
                :key="item.id"
                @click="download(item.path)"
                @mouseenter="item.show = true"
                @mouseleave="item.show = false"
                title="点击下载"
              >
                <div class="bg">
                  <icon-font
                    type="icon-txt"
                    style="font-size: 32px"
                    v-if="item.type == 'txt'"
                  ></icon-font>
                  <icon-font
                    type="icon-zip"
                    style="font-size: 32px"
                    v-else-if="item.type == 'zip'"
                  ></icon-font>
                  <icon-font
                    type="icon-markdown"
                    style="font-size: 32px"
                    v-else-if="item.type == 'md'"
                  ></icon-font>
                  <icon-font
                    type="icon-word"
                    style="font-size: 32px"
                    v-else-if="item.type == 'docx' || item.type == 'doc'"
                  ></icon-font>
                  <icon-font
                    type="icon-xlsx"
                    style="font-size: 32px"
                    v-else-if="item.type == 'xlsx' || item.type == 'xls'"
                  ></icon-font>
                  <icon-font
                    type="icon-html"
                    style="font-size: 32px"
                    v-else-if="item.type == 'html' || item.type == 'htm'"
                  ></icon-font>
                  <icon-font
                    type="icon-js"
                    style="font-size: 32px"
                    v-else-if="item.type == 'js'"
                  ></icon-font>
                  <icon-font
                    type="icon-pdf"
                    style="font-size: 32px"
                    v-else-if="item.type == 'pdf'"
                  ></icon-font>
                  <icon-font
                    type="icon-other"
                    style="font-size: 32px"
                    v-else
                  ></icon-font>
                </div>
                <div class="name" :title="item.name">{{ item.name }}</div>
                <span
                  class="delete"
                  v-show="item.show"
                  @click.stop="deleteFile(item.id)"
                >
                  <a-icon type="delete"></a-icon>
                </span>
              </div>
              <div
                class="spaceFile"
                v-for="item in 5 - (fileList.length % 5)"
              ></div>
            </div>
          </div>
        </div>
        <div class="flexItem" style="width: 40%">
          <h3 style="padding: 16px 16px 0">参与者</h3>
          <div class="participantList">
            <template v-for="(item, index) in participantListAdd">
              <div
                class="participant"
                @mouseenter="item.iconShow = true"
                @mouseleave="item.iconShow = false"
              >
                <a-icon
                  type="close-circle"
                  class="icon_remove"
                  v-show="item.iconShow && index !== 0 && isCreator"
                  @click.stop="deleteExecutor(index, 'PARTICIPANT')"
                />
                <a-avatar size="small" :src="item.avatar" />
              </div>
            </template>
            <a-tooltip>
              <template slot="title"> 添加参与者 </template>
              <div
                @click.stop="participantListShow = true"
                class="addParticipant"
              >
                <a-icon type="plus" style="color: #fff; font-size: 16px" />
              </div>
            </a-tooltip>
            <div
              class="nameList"
              v-show="participantListShow"
              @click.stop="participantListShow = true"
            >
              <div class="search">
                <a-input
                  v-model="name"
                  placeholder="搜索"
                  ref="search"
                  @change="getProjectMemberList()"
                />
              </div>
              <div class="userlist">
                <template v-if="projectMemberList.length > 0">
                  <div
                    v-for="item in projectMemberList"
                    :key="item.userId"
                    class="userItem"
                    @click.stop="checkUser(item, 'PARTICIPANT')"
                  >
                    <a-avatar :src="item.avatar" />
                    <span style="margin-left: 15px">{{ item.userName }}</span>
                  </div>
                </template>
                <a-empty v-else />
              </div>
            </div>
          </div>
          <div class="changeLogList" ref="changeLogList">
            <template v-for="item in changeLogList">
              <div class="OPERATION" v-if="item.type == 'OPERATION'">
                <span style="float: left; margin-right: 10px">{{
                  item.userName
                }}</span>
                <span
                  style="
                    display: inline-block;
                    width: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  v-html="item.content"
                ></span>
                <span style="float: right">{{ item.gmtCreated }}</span>
              </div>
              <div
                class="COMMENT"
                v-if="item.type == 'COMMENT'"
                @mouseenter="item.show = true"
                @mouseleave="item.show = false"
              >
                <div style="float: left; color: #000">
                  <div
                    style="
                      margin-right: 10px;
                      font-size: 14px;
                      margin-bottom: 5px;
                    "
                  >
                    {{ item.userName }}
                    <span v-show="item.show && item.editable">
                      <a-button
                        type="link"
                        style="
                          font-size: 14px;
                          padding: 0 5px;
                          height: 17px;
                          line-height: 17px;
                        "
                        @click="editComment(item)"
                        >编辑</a-button
                      >
                      <a-button
                        type="link"
                        style="
                          font-size: 14px;
                          padding: 0 5px;
                          height: 17px;
                          line-height: 17px;
                        "
                        @click="deleteComment(item)"
                        >删除</a-button
                      >
                    </span>
                  </div>
                  <div>{{ item.content }}</div>
                </div>
                <span style="float: right">{{ item.gmtCreated }}</span>
              </div>
            </template>
          </div>
          <div class="comment">
            <a-input
              v-model="comment"
              placeholder="请输入评论"
              class="title commentInput"
              @pressEnter="addComment"
            />
            <div style="overflow: auto">
              <a-button type="link" style="float: right" @click="addComment"
                >发布</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      title="添加成员"
      v-model="addUserShow"
      class="action-class"
      width="500px"
      @cancel="addUserClose"
    >
      <a-form style="margin-top: 20px; padding: 0 15px">
        <a-form-item
          label="成员:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-select
            v-model="user"
            placeholder="选择成员"
            :filter-option="false"
            @search="getUserList"
            showSearch
          >
            <a-select-option
              v-for="item in userList"
              :key="item.userId"
              :value="item.loginName"
            >
              {{ item.userName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: right">
          <a-button key="back" @click="addUserShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addUser_submit()"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-drawer
      title="项目成员"
      :width="350"
      :visible="visible"
      @close="close"
      :mask="false"
      :drawerStyle="{ backgroundColor: '#f7f7f7' }"
      wrapClassName="drawer"
      @click.native.stop="stop"
    >
      <a-input
        placeholder="搜索成员"
        v-model="name"
        @change="getProjectMemberList()"
      />
      <div class="projectMemberList">
        <div
          class="projectMember"
          style="color: #2ca9f4; cursor: pointer"
          @click="addUserShow = true"
        >
          <span class="add">
            <a-icon type="plus" style="color: #fff; font-size: 18px"></a-icon>
          </span>
          添加新成员
        </div>
        <div
          class="projectMember"
          v-for="item in projectMemberList"
          :key="item.userId"
        >
          <a-avatar :size="32" :src="item.avatar" style="margin-right: 10px" />
          {{ item.userName }}
          <span class="delete" @click="deleteProjectMember(item.userId)">
            <a-icon type="delete"></a-icon>
          </span>
        </div>
      </div>
    </a-drawer>

    <a-modal
      title="编辑评论"
      v-model="editCommentShow"
      class="action-class"
      width="500px"
    >
      <a-form style="margin-top: 20px; padding: 0 15px">
        <a-form-item
          label="评论:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-textarea v-model="commentAdd" ref="title" autosize />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: right">
          <a-button key="back" @click="editCommentShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="editCommentSubmit"
            >完成</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/workbench.js";
import workbenchCol from "./workbenchCol.vue";
import moment from "moment";
import draggable from "vuedraggable";

export default {
  name: "workbench",
  components: { workbenchCol, draggable },
  props: [
    "loginAvatarUrl",
    "loginNickName",
    "loginName",
    "workTaskRefresh",
    "type",
  ],
  data() {
    return {
      addKeyVisible: false,
      editKeyVisible: false,
      loading: false,
      pageNo: 1,
      finishPageNo: 1,
      tableDataSource: [],
      finishTableDataSource: [],
      requestStatus: "ALL",
      titleAdd: "",
      rangeTime: [],
      name: "",
      priorityAdd: "",
      remarkAdd: "",
      stateAdd: undefined,
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              ["link", "image"], // 链接、图片
            ],
            handlers: {
              image: function image() {
                var fileInput = this.container.querySelector(
                  "input.ql-image[type=file]"
                );
                if (fileInput === null) {
                  fileInput = document.createElement("input");
                  fileInput.setAttribute("type", "file");
                  const uploadConfig = {
                    action: "", // 必填参数 图片上传地址
                    methods: "POST", // 必填参数 图片上传方式
                    token: "", // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
                    name: "img", // 必填参数 文件的参数名
                    size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
                    accept:
                      "image/png, image/gif, image/jpeg, image/bmp, image/x-icon", // 可选 可上传的图片格式
                  };
                  // 设置图片参数名
                  if (uploadConfig.name) {
                    fileInput.setAttribute("name", uploadConfig.name);
                  }
                  // 可设置上传图片的格式
                  fileInput.setAttribute("accept", uploadConfig.accept);
                  fileInput.classList.add("ql-image");
                  // 监听选择文件
                  function getUuid() {
                    var chars =
                      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
                        ""
                      );
                    var uuid = [],
                      i;
                    var r;
                    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
                    uuid[14] = "4";
                    for (i = 0; i < 36; i++) {
                      if (!uuid[i]) {
                        r = 0 | (Math.random() * 16);
                        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
                      }
                    }
                    return uuid.join("");
                  }
                  fileInput.addEventListener("change", () => {
                    api
                      .addWorkTaskImage({
                        file: fileInput.files[0],
                        uuid: getUuid(),
                      })
                      .then((res) => {
                        if (res.result == 200) {
                          let length = this.quill.getSelection(true).index;
                          this.quill.insertEmbed(
                            length,
                            "image",
                            res.data.imageUrl
                          );
                          this.quill.setSelection(length + 1);
                        }
                        fileInput.value = "";
                      });
                  });
                  this.container.appendChild(fileInput);
                }
                fileInput.click();
              },
            },
          },
        },
        placeholder: "请输入正文",
      },
      editorShow: true,
      activeKey: ["1"],
      iconShow: false,
      priorityList: [
        {
          label: "较低",
          value: "LOWER",
          color: "",
          order: 1,
        },
        {
          label: "普通",
          value: "ORDINARY",
          color: "blue",
          order: 2,
        },
        {
          label: "紧急",
          value: "URGENT",
          color: "orange",
          order: 3,
        },
        {
          label: "非常紧急",
          value: "VERY_URGENT",
          color: "red",
          order: 4,
        },
      ],
      executorListShow: false,
      participantListShow: false,
      projectMemberList: [],
      executorListAdd: [],
      participantListAdd: [],
      stateList: [
        {
          label: "待处理",
          value: "WAITING",
          color: "#c3c3c3",
          show: true,
          order: 1,
        },
        {
          label: "开发中",
          value: "DEVELOPING",
          color: "orange",
          show: true,
          order: 2,
        },
        {
          label: "待发布",
          value: "WAITING_DELIVERY",
          color: "#48a9c9",
          show: true,
          order: 3,
        },
        {
          label: "延期中",
          value: "DELAYING",
          color: "#f5222d",
          show: true,
          order: 4,
        },
        {
          label: "已发布",
          value: "DELIVERED",
          color: "blue",
          show: true,
          order: 5,
        },
        {
          label: "已完成",
          value: "FINISHED",
          color: "green",
          show: false,
          order: 6,
        },
      ],
      id: "",
      columns: [
        {
          title: "需求",
          width: "300px",
          show: false,
          top: false,
          type: "title",
          class: "rowTitle",
          borderHover: false,
          workbenchTypeList: [
            "GENERAL_TASK",
            "SCHEDULE_TASK",
            "INVENTORY_TASK",
          ],
        },
        {
          title: "执行者",
          width: "300px",
          show: false,
          top: false,
          type: "executor",
          borderHover: false,
          workbenchTypeList: [
            "GENERAL_TASK",
            "SCHEDULE_TASK",
            "INVENTORY_TASK",
          ],
        },
        {
          title: "任务状态",
          width: "100px",
          show: false,
          top: false,
          type: "state",
          borderHover: false,
          workbenchTypeList: ["GENERAL_TASK", "SCHEDULE_TASK"],
        },
        {
          title: "开始时间",
          width: "100px",
          show: false,
          top: false,
          type: "startDate",
          borderHover: false,
          workbenchTypeList: ["GENERAL_TASK", "SCHEDULE_TASK"],
        },
        {
          title: "截止时间",
          width: "100px",
          show: false,
          top: false,
          type: "endDate",
          borderHover: false,
          workbenchTypeList: ["GENERAL_TASK", "SCHEDULE_TASK"],
        },
        {
          title: "优先级",
          width: "90px",
          show: false,
          top: false,
          type: "priority",
          borderHover: false,
          workbenchTypeList: ["GENERAL_TASK", "SCHEDULE_TASK"],
        },
        {
          title: "操作",
          width: "100px",
          show: false,
          top: false,
          type: "action",
          borderHover: false,
          workbenchTypeList: ["SCHEDULE_TASK"],
        },
      ],
      idList: [],
      checkAll: false,
      checkFinish: false,
      titleWatch: "",
      remarkWatch: "",
      changeLogList: [],
      comment: "",
      imgList: [],
      editor: null,
      hoverIndex: 0,
      borderHoverShow: false,
      childrenShow: false,
      parentTaskTitle: "",
      parentId: null,
      childTaskList: [],
      parent: null,
      addChildrenTaskShow: true,
      screenPanelShow: false,
      screen: {
        title: "",
        finished: "no",
        executors: [],
        participants: [],
        prioritys: [],
        endDateStart: null,
        endDateEnd: null,
      },
      finishOptions: [
        { label: "是", value: "yes" },
        { label: "否", value: "no" },
      ],
      total: 0,
      editData: [],
      addEditorShow: false,
      visible: false,
      addUserShow: false,
      userList: [],
      user: undefined,
      isCreator: false,
      fileList: [],
      fileIdList: [],
      range: {
        今天: [moment(), moment()],
        本周: [moment().weekday(0), moment().weekday(4)],
        下周: [
          moment().add(
            7 - (new Date().getDay() == 0 ? 7 : new Date().getDay()) + 1,
            "days"
          ),
          moment().add(
            7 - (new Date().getDay() == 0 ? 7 : new Date().getDay()) + 5,
            "days"
          ),
        ],
      },
      editCommentShow: false,
      commentAdd: "",
      commentId: "",
      schedule: {
        type: "week",
        interval: 0,
        value: [],
      },
      scheduleShow: false,
      scheduleTypeList: [
        { label: "每周重复", value: "week" },
        { label: "每月重复", value: "month" },
      ],
      weekDayList: [
        { label: "一", value: 1, show: false },
        { label: "二", value: 2, show: false },
        { label: "三", value: 3, show: false },
        { label: "四", value: 4, show: false },
        { label: "五", value: 5, show: false },
        { label: "六", value: 6, show: false },
        { label: "日", value: 7, show: false },
      ],
      monthDayList: [
        {
          value: 1,
          show: false,
        },
        {
          value: 2,
          show: false,
        },
        {
          value: 3,
          show: false,
        },
        {
          value: 4,
          show: false,
        },
        {
          value: 5,
          show: false,
        },
        {
          value: 6,
          show: false,
        },
        {
          value: 7,
          show: false,
        },
        {
          value: 8,
          show: false,
        },
        {
          value: 9,
          show: false,
        },
        {
          value: 10,
          show: false,
        },
        {
          value: 11,
          show: false,
        },
        {
          value: 12,
          show: false,
        },
        {
          value: 13,
          show: false,
        },
        {
          value: 14,
          show: false,
        },
        {
          value: 15,
          show: false,
        },
        {
          value: 16,
          show: false,
        },
        {
          value: 17,
          show: false,
        },
        {
          value: 18,
          show: false,
        },
        {
          value: 19,
          show: false,
        },
        {
          value: 20,
          show: false,
        },
        {
          value: 21,
          show: false,
        },
        {
          value: 22,
          show: false,
        },
        {
          value: 23,
          show: false,
        },
        {
          value: 24,
          show: false,
        },
        {
          value: 25,
          show: false,
        },
        {
          value: 26,
          show: false,
        },
        {
          value: 27,
          show: false,
        },
        {
          value: 28,
          show: false,
        },
        {
          value: 29,
          show: false,
        },
        {
          value: 30,
          show: false,
        },
        {
          value: 31,
          show: false,
        },
      ],
      watchSchedule: {
        type: "week",
        interval: 0,
        value: [],
      },
    };
  },
  computed: {
    formatDateEndTime() {
      // 开始时间
      if (this.rangeTime[1]) {
        return this.$common.dayFormat(this.rangeTime[1]) + " 23:59:59";
      }
      return "";
    },
    formatDateStartTime() {
      // 结束时间
      if (this.rangeTime[0]) {
        return this.$common.dayFormat(this.rangeTime[0]) + " 00:00:00";
      }
      return "";
    },
  },
  watch: {
    idList: {
      deep: true,
      handler(newVal) {
        let length = this.tableDataSource.length;
        this.tableDataSource.forEach((item) => {
          if (item.childTaskList && item.childrenShow) {
            length += item.childTaskList.length;
          }
        });
        if (newVal.length == length && newVal.length > 0) {
          console.log(123);
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
      },
    },
    executorListShow: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });
        } else {
          this.name = "";
          this.getProjectMemberList();
        }
      },
    },
    participantListShow: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });
        } else {
          this.name = "";
          this.getProjectMemberList();
        }
      },
    },
    editorShow: {
      handler(newVal) {
        if (!newVal) {
          if (this.remarkAdd !== this.remarkWatch) {
            this.remarkAdd = this.remarkWatch;
          }
        }
      },
    },
    // websocket
    workTaskRefresh: {
      handler(newVal) {
        if (newVal) {
          this.query();
        }
      },
    },
    screen: {
      deep: true,
      handler(newVal) {
        this.query();
      },
    },
    screenPanelShow: {
      handler(newVal) {
        const screenPanelHide = (e) => {
          if (e.keyCode == 27) {
            this.screenPanelShow = false;
          }
        };
        if (newVal) {
          document.addEventListener("keydown", screenPanelHide);
        } else {
          document.removeEventListener("keydown", screenPanelHide);
        }
      },
    },
    addKeyVisible: {
      handler(newVal) {
        if (!newVal) {
          setTimeout(() => {
            this.addEditorShow = false;
          }, 1000);
        }
      },
    },
    scheduleShow: {
      handler(newVal) {
        if (!newVal && this.editKeyVisible) {
          this.schedule = this.watchSchedule;
          this.weekDayList.forEach((day) => {
            this.$set(day, "show", false);
          });
          this.monthDayList.forEach((day) => {
            this.$set(day, "show", false);
          });
          if (this.schedule.type == "week") {
            this.schedule.value.forEach((item) => {
              this.weekDayList.forEach((day) => {
                if (day.value == item) {
                  this.$set(day, "show", true);
                }
              });
            });
          } else {
            this.schedule.value.forEach((item) => {
              this.monthDayList.forEach((day) => {
                if (day.value == item) {
                  this.$set(day, "show", true);
                }
              });
            });
          }
        }
      },
    },
  },
  created() {
    this.query();
    this.getProjectMemberList();
    document.body.addEventListener("click", () => {
      this.executorListShow = false;
      this.participantListShow = false;
      this.listAni(null, false);
      this.screenPanelShow = false;
      this.visible = false;
    });
  },
  methods: {
    moment,
    scheduleTypeChange() {
      this.weekDayList.forEach((item) => {
        this.$set(item, "show", false);
      });
      this.monthDayList.forEach((item) => {
        this.$set(item, "show", false);
      });
      this.schedule.value.splice(0);
      console.log(this.schedule.value);
    },
    scheduleChange() {
      let data = {
        id: this.id,
        schedule: JSON.stringify(this.schedule),
      };
      api.updateSchedule(data).then((res) => {
        if (res.result == 200) {
          this.watchSchedule = this.schedule;
          this.scheduleShow = false;
          this.getChangeLogList();
        }
      });
    },
    checkDay(index) {
      if (this.schedule.type == "week") {
        this.$set(
          this.weekDayList[index],
          "show",
          !this.weekDayList[index].show
        );
        if (this.weekDayList[index].show) {
          this.schedule.value.push(this.weekDayList[index].value);
        } else {
          this.schedule.value = this.schedule.value.filter((item) => {
            return this.weekDayList[index].value != item;
          });
        }
      } else {
        this.$set(
          this.monthDayList[index],
          "show",
          !this.monthDayList[index].show
        );
        if (this.monthDayList[index].show) {
          this.schedule.value.push(this.monthDayList[index].value);
        } else {
          this.schedule.value = this.schedule.value.filter((item) => {
            return this.monthDayList[index].value != item;
          });
        }
      }
      this.schedule.value.sort();
    },
    end() {
      let idList = [];
      this.tableDataSource.forEach((item) => {
        idList.push(item.id);
      });
      idList = idList.reverse();
      api.changeOrder({ idList }).then((res) => {
        if (res.result == 200) {
          this.$message.success("排序修改成功");
        }
      });
    },
    editCommentSubmit() {
      api
        .editWorkTaskComment({ id: this.commentId, comment: this.commentAdd })
        .then((res) => {
          if (res.result == 200) {
            this.editCommentShow = false;
            this.getChangeLogList();
          }
        });
    },
    editComment(item) {
      this.commentId = item.id;
      this.commentAdd = item.content;
      this.editCommentShow = true;
    },
    deleteComment(item) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确认删除这条评论吗?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteWorkTaskComment({ id: item.id }).then((res) => {
            if (res.result == 200) {
              this.getChangeLogList();
            }
          });
        },
      });
    },
    deleteFile(id) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除该文件吗?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteWorkTaskFile({ id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.fileList = this.fileList.filter((item) => {
                return item.id !== id;
              });
            }
          });
        },
        onCancel: () => {},
      });
    },
    download(path) {
      location.href = path;
    },
    customRequest(info) {
      let data = { file: info.file };
      if (this.editKeyVisible) {
        data.taskId = this.id;
      }
      api.addWorkTaskFile(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("上传成功");
          this.fileList.push(res.data);
          this.fileList.forEach((item) => {
            this.$set(item, "show", false);
            let type = item.name.split(".");
            if (type.length > 0) {
              this.$set(item, "type", type[type.length - 1]);
            }
          });
          this.fileIdList.push(res.data.id);
        }
      });
    },
    deleteProjectMember(id) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除该成员吗？</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteProjectMember({ id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getProjectMemberList();
            }
          });
        },
        onCancel: () => {},
      });
    },
    addUserClose() {
      this.user = undefined;
      this.getUserList();
    },
    addUser_submit() {
      api.addProjectMember({ loginName: this.user }).then((res) => {
        if (res.result == 200) {
          this.addUserShow = false;
          this.getProjectMemberList();
        }
      });
    },
    close() {
      this.visible = false;
      this.name = "";
      this.getProjectMemberList();
    },
    getUserList(name) {
      api.userList({ name }).then((res) => {
        if (res.result == 200) {
          this.userList.splice(0);
          this.userList.push(...res.data.userList);
        }
      });
    },
    workTaskDetail(id) {
      if (id) {
        this.id = id;
      }
      api.workTaskDetail({ id: this.id }).then((res) => {
        if (res.result == 200) {
          this.editData.splice(0);
          this.editData.push(res.data);
          this.init(this.editData, true);
        }
      });
    },
    reset() {
      this.screen = {
        title: "",
        finished: "no",
        executors: [],
        participants: [],
        prioritys: [],
        endDateStart: null,
        endDateEnd: null,
      };
    },
    addChildrenTask_cancel() {
      this.childTaskList.pop();
      this.addChildrenTaskShow = true;
    },
    addChildrenTask_submit() {
      let data = JSON.parse(
        JSON.stringify(this.childTaskList[this.childTaskList.length - 1])
      );
      if (data.startDate) {
        data.startDate =
          this.$common.dayFormat(data.rangeDate[0]) + " 00:00:00";
      }
      if (data.endDate) {
        data.endDate = this.$common.dayFormat(data.rangeDate[1]) + " 23:59:59";
      }
      delete data.listShow;
      data.parentId = this.id;
      let executorList = [];
      data.executorList.forEach((item) => {
        executorList.push(item.executor);
      });
      data.executorList = executorList;
      api.addWorkTask(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.workTaskDetail();
        }
      });
    },
    addChildrenTask() {
      this.childTaskList.push({
        title: "",
        state: "WAITING",
        priority: "ORDINARY",
        executorList: [],
        remark: "",
        startDate: null,
        endDate: null,
        rangeDate: [],
        isCreator: true,
      });
      this.addChildrenTaskShow = false;
    },
    childTaskShow(item) {
      this.$set(item, "childrenShow", !item.childrenShow);
    },
    addClose() {
      this.remarkAdd = "";
      this.remarkWatch = "";
    },
    editClose() {
      this.parent = null;
      this.remarkAdd = "";
      this.remarkWatch = "";
      this.query();
    },
    listAni(index, listShow) {
      this.tableDataSource.forEach((item, itemIndex) => {
        this.$set(item, "listShow", false);
        if (listShow) {
          if (index == itemIndex) {
            this.$set(item, "listShow", true);
          }
        }
      });
      if (this.childTaskList.length > 0) {
        this.childTaskList.forEach((item) => {
          this.$set(item, "listShow", false);
        });
      }
    },
    resizeHide(index) {
      this.$set(this.columns[index], "borderHover", false);
      this.borderHoverShow = false;
    },
    resizeHover(e, index) {
      this.$set(this.columns[index], "borderHover", true);
      this.hoverIndex = index;
      this.borderHoverShow = true;
      let el = e.target;
      el.onmousedown = (e) => {
        if (e.button == 2) {
          return;
        }
        let clientX = e.clientX;
        let width = this.columns[index].width.split("px")[0] * 1;
        document.onmousemove = (e) => {
          e.preventDefault();
          e.stopPropagation();
          let x = e.clientX - clientX;
          this.$set(this.columns[index], "width", width + x + "px");
        };
        document.onmouseup = (e) => {
          document.onmousedown = null;
          document.onmousemove = null;
        };
      };
    },
    pasteImg(editor) {
      let quill = editor.quill;
      quill.root.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              api
                .addWorkTaskImage({
                  file,
                  uuid: this.getUuid(),
                })
                .then((res) => {
                  if (res.result == 200) {
                    let length = quill.getSelection(true).index;
                    quill.insertEmbed(length, "image", res.data.imageUrl);
                    quill.setSelection(length + 1);
                  }
                });
            });
          }
        },
        false
      );
    },
    // 生成uuid
    getUuid() {
      var chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
          ""
        );
      var uuid = [],
        i;
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
      return uuid.join("");
    },
    imgClick(e) {
      if (e.target.nodeName == "IMG") {
        let src = e.target.getAttribute("src");
        let imgList = document
          .querySelector("#editorHide")
          .querySelectorAll("img");
        let imgIndex = 0;
        this.imgList.splice(0);
        for (let index = 0; index < imgList.length; index++) {
          this.imgList.push(imgList[index].src);
          if (src) {
            if (src == imgList[index].src) {
              imgIndex = index;
            }
          } else {
            imgIndex = 0;
          }
        }
        this.$hevueImgPreview({
          multiple: true,
          nowImgIndex: imgIndex,
          imgList: this.imgList,
          clickMaskCLose: "close",
        });
      } else {
        this.editorShow = true;
      }
    },
    addComment() {
      if (this.comment) {
        api
          .addWorkTaskComment({ taskId: this.id, comment: this.comment })
          .then((res) => {
            if (res.result == 200) {
              this.comment = "";
              this.getChangeLogList();
            }
          });
      } else {
        this.$message.error("请输入评论");
      }
    },
    stateChange(edit, id, state) {
      let data = { id: this.id, state: this.stateAdd };
      if (id) {
        data.id = id;
        data.state = state;
      }
      api.updateState(data).then((res) => {
        if (res.result == 200) {
          if (edit) {
            this.getChangeLogList();
          }
        }
      });
    },
    priorityChange() {
      api
        .updatePriority({ id: this.id, priority: this.priorityAdd })
        .then((res) => {
          if (res.result == 200) {
            this.getChangeLogList();
          }
        });
    },
    dateChange() {
      this.updateStartEndTime(this.formatDateStartTime, true);
      this.updateStartEndTime(this.formatDateEndTime, false);
    },
    updateStartEndTime(dateTime, start, id) {
      let data = { id: this.id, dateTime, start };
      if (id) {
        data.id = id;
      }
      api.updateStartEndTime(data).then((res) => {
        if (res.result == 200) {
          this.getChangeLogList();
        }
      });
    },
    updateExecutor(type, arr) {
      let executorList = [];
      if (arr) {
        if (type == "EXECUTOR") {
          arr.forEach((item) => {
            executorList.push(item.loginName);
          });
        } else {
          arr.forEach((item) => {
            if (item.type !== "CREATOR") {
              executorList.push(item.loginName);
            }
          });
        }
      } else {
        if (type == "EXECUTOR") {
          this.executorListAdd.forEach((item) => {
            executorList.push(item.loginName);
          });
        } else {
          this.participantListAdd.forEach((item) => {
            if (item.type !== "CREATOR") {
              executorList.push(item.loginName);
            }
          });
        }
      }
      api.updateExecutor({ id: this.id, executorList, type }).then((res) => {
        if (res.result == 200) {
          if (arr) {
            if (type == "EXECUTOR") {
              this.executorListAdd = arr;
            } else {
              this.participantListAdd = arr;
            }
          }
          this.getChangeLogList();
        }
      });
    },
    updateTitle() {
      if (this.titleAdd !== this.titleWatch) {
        api.updateTitle({ id: this.id, title: this.titleAdd }).then((res) => {
          if (res.result == 200) {
            this.titleWatch = this.titleAdd;
            this.getChangeLogList();
            this.$message.success("标题修改成功");
          }
        });
      }
    },
    updateRemark() {
      if (this.remarkAdd !== this.remarkWatch) {
        api
          .updateRemark({ id: this.id, remark: this.remarkAdd })
          .then((res) => {
            if (res.result == 200) {
              this.remarkWatch = this.remarkAdd;
              this.editorShow = false;
              this.getChangeLogList();
            }
          });
      } else {
        this.editorShow = false;
      }
    },
    deleteWork() {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确认批量删除吗？</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api
            .deleteWorkTask({ idList: this.idList })
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("删除成功");
                this.query();
              }
            })
            .catch((err) => {});
        },
      });
    },
    markFinishedWork() {
      this.$confirm({
        title: "确认",
        content: (h) => <div style="color:red;">确认批量标记完成吗？</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api
            .markFinished({ idList: this.idList })
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("操作成功");
                this.query();
              }
            })
            .catch((err) => {});
        },
      });
    },
    migrateTask() {
      this.$confirm({
        title: "确认",
        content: (h) => <div style="color:red;">确认迁移这些任务吗？</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api
            .editType({
              idList: this.idList,
              type:
                this.type == "GENERAL_TASK" ? "INVENTORY_TASK" : "GENERAL_TASK",
            })
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("操作成功");
                this.query();
              }
            })
            .catch((err) => {});
        },
      });
    },
    wordSort(item, a, b) {
      let reg = /[a-zA-Z0-9]/;
      if (reg.test(a) || reg.test(b)) {
        return this.orderSort(item, a, b);
      } else {
        if (!item.show) {
          return b.localeCompare(a);
        } else if (!item.top) {
          return a.localeCompare(b);
        }
      }
    },
    executorSort(item, a, b) {
      if (!item.show) {
        if (b.executorCount > a.executorCount) {
          return 1;
        } else if (b.executorCount < a.executorCount) {
          return -1;
        } else {
          return this.wordSort(item, a.executor, b.executor);
        }
      } else if (!item.top) {
        if (a.executorCount > b.executorCount) {
          return 1;
        } else if (a.executorCount < b.executorCount) {
          return -1;
        } else {
          return this.wordSort(item, a.executor, b.executor);
        }
      }
    },
    orderSort(item, a, b) {
      if (!item.show) {
        if (b > a) {
          return 1;
        } else if (b < a) {
          return -1;
        } else {
          return 0;
        }
      } else if (!item.top) {
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      }
    },
    dateSort(item, a, b) {
      return this.orderSort(item, new Date(a).getTime(), new Date(b).getTime());
    },
    sort(item) {
      this.tableDataSource.sort((a, b) => {
        if (item.type == "title") {
          return this.wordSort(item, a.title, b.title);
        } else if (item.type == "executor") {
          return this.executorSort(item, a, b);
        } else if (item.type == "state") {
          return this.orderSort(item, a.stateOrder, b.stateOrder);
        } else if (item.type == "priority") {
          return this.orderSort(item, a.priorityOrder, b.priorityOrder);
        } else if (item.type == "startDate") {
          return this.dateSort(item, a.startDate, b.startDate);
        } else if (item.type == "endDate") {
          return this.dateSort(item, a.endDate, b.endDate);
        }
      });
      this.columns.forEach((columnsItem) => {
        if (columnsItem.type !== item.type) {
          this.$set(columnsItem, "show", false);
          this.$set(columnsItem, "top", false);
        }
      });
      if (!item.show) {
        item.show = true;
      } else if (!item.top) {
        item.top = true;
      } else {
        item.show = false;
        item.top = false;
        this.query();
      }
    },
    deleteChildExecutor(index, executorIndex, type) {
      this.childTaskList[index].executorList.splice(executorIndex, 1);
      if (this.childTaskList[index].id) {
        let executorList = [];
        this.childTaskList[index].executorList.forEach((item) => {
          executorList.push(item.executor);
        });
        api.updateExecutor({
          id: this.childTaskList[index].id,
          executorList,
          type,
        });
      }
    },
    deleteExecutor(index, type) {
      let arr = [];
      if (type == "EXECUTOR") {
        arr = JSON.parse(JSON.stringify(this.executorListAdd));
      } else {
        arr = JSON.parse(JSON.stringify(this.participantListAdd));
      }
      arr.splice(index, 1);
      if (this.editKeyVisible) {
        this.updateExecutor(type, arr);
      }
      if (this.addKeyVisible) {
        this.executorListAdd = arr;
      }
    },
    checkChildUser(item, index, type) {
      const res = new Map();
      this.childTaskList[index].executorList.push({
        executor: item.loginName,
        avatar: item.avatar,
        userName: item.userName,
        iconShow: false,
        type,
      });
      let arr = this.childTaskList[index].executorList.filter(
        (executor) =>
          !res.has(executor.executor) &&
          res.set(executor.executor, executor.executor)
      );
      this.$set(this.childTaskList[index], "executorList", arr);
      if (this.childTaskList[index].id) {
        let executorList = [];
        this.childTaskList[index].executorList.forEach((executorItem) => {
          executorList.push(executorItem.executor);
        });
        api.updateExecutor({
          id: this.childTaskList[index].id,
          executorList,
          type,
        });
      }
      this.listAni(null, false);
    },
    checkUser(item, type) {
      const res = new Map();
      if (type == "EXECUTOR") {
        this.executorListAdd.push({
          loginName: item.loginName,
          avatar: item.avatar,
          userName: item.userName,
          iconShow: false,
          type,
        });
        this.executorListAdd = this.executorListAdd.filter(
          (executor) =>
            !res.has(executor.loginName) &&
            res.set(executor.loginName, executor.loginName)
        );
      } else {
        this.participantListAdd.push({
          loginName: item.loginName,
          avatar: item.avatar,
          userName: item.userName,
          iconShow: false,
          type,
        });
        this.participantListAdd = this.participantListAdd.filter(
          (executor) =>
            !res.has(executor.loginName) &&
            res.set(executor.loginName, executor.loginName)
        );
      }
      if (this.editKeyVisible) {
        this.updateExecutor(type);
      }
    },
    changeDate() {
      this.tableDataSource.forEach((item) => {
        if (item.startDate) {
          this.$set(item, "startDateLabel", this.dateFormat(item.startDate));
        } else {
          this.$set(item, "startDateLabel", "待设置");
        }
        if (item.endDate) {
          this.$set(item, "endDateLabel", this.dateFormat(item.endDate));
          if (
            item.state !== "DELIVERED" &&
            item.state !== "FINISHED" &&
            item.state !== "DELAYING"
          ) {
            let timeDifference =
              new Date(
                this.$common.dayFormat(item.endDate) + " 23:59:59"
              ).getTime() - new Date().getTime();
            if (timeDifference < 0) {
              this.$set(item, "endDateColor", "red");
            } else if (timeDifference < 86400000) {
              this.$set(item, "endDateColor", "#fa8c15");
            } else {
              this.$set(item, "endDateColor", "#1b9aee");
            }
          }
        } else {
          this.$set(item, "endDateLabel", "待设置");
          this.$set(item, "endDateColor", "");
        }
      });
    },
    getProjectMemberList(name) {
      let data = { name: this.name };
      if (name) {
        data.name = name;
      }
      api.projectMemberList(data).then((res) => {
        if (res.result == 200) {
          this.projectMemberList.splice(0);
          this.projectMemberList.push(...res.data.userList);
        }
      });
    },
    stop() {},
    onCheckAll() {
      if (this.tableDataSource.length > 0) {
        this.tableDataSource.forEach((item) => {
          this.$set(item, "check", this.checkAll);
          if (item.childTaskList && item.childrenShow) {
            item.childTaskList.forEach((child) => {
              this.$set(child, "check", this.checkAll);
            });
          }
        });
      }
      this.onCheck();
    },
    onCheck() {
      this.idList.splice(0);
      if (this.tableDataSource.length > 0) {
        this.tableDataSource.forEach((item) => {
          if (item.check) {
            this.idList.push(item.id);
          }
          if (item.childTaskList) {
            item.childTaskList.forEach((child) => {
              if (child.check) {
                this.idList.push(child.id);
              }
            });
          }
        });
      }
    },
    loadMore() {
      this.pageNo += 1;
      this.query();
    },
    // 查询列表
    query() {
      let data = {
        pageNo: this.pageNo,
        pageSize: 1000,
        finished: false,
        executors: this.screen.executors.join(","),
        participants: this.screen.participants.join(","),
        prioritys: this.screen.prioritys.join(","),
        title: this.screen.title,
        endDateStart:
          this.$common.dayFormat(this.screen.endDateStart) + " 23:59:59",
        endDateEnd:
          this.$common.dayFormat(this.screen.endDateEnd) + " 23:59:59",
        type: this.type,
      };
      if (this.screen.finished == "yes") {
        data.finished = true;
      } else {
        data.finished = false;
      }
      if (!this.screen.endDateStart) {
        data.endDateStart = "";
      }
      if (!this.screen.endDateEnd) {
        data.endDateEnd = "";
      }
      api
        .workTaskPage(data)
        .then((res) => {
          if (res.result === 200) {
            this.total = res.data.total;
            let childrenShowIdList = this.tableDataSource.filter((item) => {
              return item.childrenShow;
            });
            this.tableDataSource.splice(0);
            if (res.data.records) {
              this.tableDataSource.push(...res.data.records);
              this.tableDataSource.forEach((item) => {
                childrenShowIdList.forEach((children) => {
                  if (children.id == item.id) {
                    this.$set(item, "childrenShow", true);
                  }
                });
              });
              this.init(this.tableDataSource);
            }
            this.idList.splice(0);
            if (this.workTaskRefresh) {
              this.$emit("stopWorkTaskRefresh");
            }
          }
        })
        .catch((err) => {});
    },
    // 初始化数据，对数据进行处理
    init(arr, children) {
      arr.forEach((item) => {
        this.$set(item, "check", false);
        this.$set(item, "listShow", false);
        if (!item.childrenShow) {
          this.$set(item, "childrenShow", false);
        }
        if (item.startDate) {
          this.$set(item, "startDateLabel", this.dateFormat(item.startDate));
          this.$set(item, "startDate", moment(item.startDate));
        } else {
          this.$set(item, "startDateLabel", "待设置");
        }
        if (item.endDate) {
          this.$set(item, "endDateLabel", this.dateFormat(item.endDate));
          this.$set(item, "endDate", moment(item.endDate));
        } else {
          this.$set(item, "endDateLabel", "待设置");
        }
        this.$set(item, "rangeDate", [item.startDate, item.endDate]);
        if (!item.executor) {
          this.$set(item, "executor", "");
        }
        this.$set(item, "executorCount", 0);
        if (item.executorList) {
          let arr = item.executorList.filter((item) => {
            return item.type == "EXECUTOR";
          });
          let arr2 = item.executorList.filter((item) => {
            return item.type == "PARTICIPANT";
          });
          let arr3 = item.executorList.filter((item) => {
            return item.type == "CREATOR";
          });
          if (arr3[0]) {
            arr2.unshift(arr3[0]);
            if (arr3[0].executor == this.loginName) {
              this.$set(item, "isCreator", true);
            } else {
              this.$set(item, "isCreator", false);
            }
          }
          this.$set(item, "executorList", arr);
          this.$set(item, "participantList", arr2);
          let stateSame = true;
          item.executorList.forEach((executorItem, executorIndex) => {
            this.$set(executorItem, "iconShow", false);
            if (
              executorIndex > 0 &&
              executorItem.state !== item.executorList[executorIndex - 1].state
            ) {
              stateSame = false;
            }
            this.stateList.forEach((state) => {
              if (executorItem.state == state.value) {
                this.$set(executorItem, "stateLabel", state.label);
                this.$set(executorItem, "stateColor", state.color);
              }
            });
          });
          this.$set(item, "stateSame", stateSame);
          item.participantList.forEach((participantItem) => {
            this.$set(participantItem, "iconShow", false);
          });
          if (item.executorList.length > 0) {
            this.$set(item, "executor", item.executorList[0].executor);
          }
          this.$set(item, "executorCount", item.executorList.length);
        } else {
          this.$set(item, "executorList", []);
        }
        this.stateList.forEach((state) => {
          if (state.value == item.state) {
            this.$set(item, "stateOrder", state.order);
          }
        });
        this.priorityList.forEach((priority) => {
          if (priority.value == item.priority) {
            this.$set(item, "priorityOrder", priority.order);
          }
        });
        this.$set(item, "endDateColor", "");
        if (item.endDate) {
          if (
            item.state !== "DELIVERED" &&
            item.state !== "FINISHED" &&
            item.state !== "DELAYING"
          ) {
            let timeDifference =
              new Date(item.endDate).getTime() - new Date().getTime();
            if (timeDifference < 0) {
              this.$set(item, "endDateColor", "red");
            } else if (timeDifference < 86400000) {
              this.$set(item, "endDateColor", "#fa8c15");
            } else {
              this.$set(item, "endDateColor", "#1b9aee");
            }
          }
        }
        if (item.childTaskList) {
          this.init(item.childTaskList);
        }
      });

      this.columns.forEach((columnsItem) => {
        if (columnsItem.show) {
          if (columnsItem.top) {
            columnsItem.top = false;
          } else {
            columnsItem.show = false;
          }
          this.sort(columnsItem);
        }
      });
      if (children) {
        this.edit(arr[0]);
      }
    },
    dateFormat(date) {
      let dateLabel = moment(date).calendar();
      if (dateLabel.indexOf("/") !== -1) {
        let year = new Date(date).getUTCFullYear();
        if (year !== dateLabel.split("/")[0] * 1) {
          return moment(date).format("LL");
        } else {
          return moment(date).format("MMMDo");
        }
      } else {
        return dateLabel.substring(0, dateLabel.length - 5);
      }
    },
    // 新增
    add(parentTaskTitle, parentId) {
      this.priorityAdd = "ORDINARY";
      this.remarkAdd = "";
      this.remarkWatch = "";
      if (this.type == "SCHEDULE_TASK") {
        this.scheduleShow = false;
        this.schedule = {
          type: "week",
          interval: 0,
          value: [],
        };
      }
      this.executorListAdd = [
        {
          loginName: this.loginName,
          avatar: this.loginAvatarUrl,
          userName: this.loginNickName,
          iconShow: false,
        },
      ];
      this.rangeTime = [];
      this.titleAdd = "";
      if (parentTaskTitle) {
        this.childrenShow = true;
        this.parentTaskTitle = parentTaskTitle;
        this.parentId = parentId;
      } else {
        this.childrenShow = false;
        this.parentTaskTitle = "";
        this.parentId = null;
      }
      this.fileList.splice(0);
      this.addEditorShow = true;
      this.addKeyVisible = true;
      this.stateAdd = "WAITING";
      this.$nextTick(() => {
        this.$refs.title.focus();
        this.pasteImg(this.$refs.addQuillEditor);
      });
    },
    edit(item) {
      this.id = item.id;
      this.priorityAdd = item.priority;
      this.remarkAdd = item.remark;
      this.remarkWatch = item.remark;
      this.editorShow = false;
      this.isCreator = item.isCreator;
      this.comment = "";
      if (this.type == "SCHEDULE_TASK") {
        this.schedule = JSON.parse(item.schedule);
        this.watchSchedule = JSON.parse(item.schedule);
        this.scheduleShow = false;
        this.weekDayList.forEach((day) => {
          this.$set(day, "show", false);
        });
        this.monthDayList.forEach((day) => {
          this.$set(day, "show", false);
        });
        if (this.schedule.type == "week") {
          this.schedule.value.forEach((item) => {
            this.weekDayList.forEach((day) => {
              if (day.value == item) {
                this.$set(day, "show", true);
              }
            });
          });
        } else {
          this.schedule.value.forEach((item) => {
            this.monthDayList.forEach((day) => {
              if (day.value == item) {
                this.$set(day, "show", true);
              }
            });
          });
        }
      }
      if (item.parentId) {
        this.parentId = item.parentId;
      } else {
        this.parentId = null;
      }
      if (item.childTaskList) {
        this.childTaskList = item.childTaskList;
        this.init(this.childTaskList);
        this.addChildrenTaskShow = true;
        this.parent = item;
      } else {
        this.childTaskList = [];
      }
      if (item.fileList) {
        this.fileList = item.fileList;
        this.fileList.forEach((item) => {
          this.$set(item, "show", false);
          let type = item.name.split(".");
          if (type.length > 0) {
            this.$set(item, "type", type[type.length - 1]);
          }
        });
      } else {
        this.fileList.splice(0);
      }
      this.executorListAdd.splice(0);
      this.participantListAdd.splice(0);
      if (item.executorList) {
        item.executorList.forEach((executorItem) => {
          this.executorListAdd.push({
            loginName: executorItem.executor,
            avatar: executorItem.avatar,
            userName: executorItem.userName,
            iconShow: false,
          });
        });
      }
      if (item.participantList) {
        item.participantList.forEach((participantItem) => {
          this.participantListAdd.push({
            loginName: participantItem.executor,
            avatar: participantItem.avatar,
            userName: participantItem.userName,
            iconShow: false,
            type: participantItem.type,
          });
        });
      }
      if (item.startDate && item.endDate) {
        this.rangeTime = [moment(item.startDate), moment(item.endDate)];
      } else if (item.startDate) {
        this.rangeTime = [moment(item.startDate), null];
      } else if (item.endDate) {
        this.rangeTime = [null, moment(item.endDate)];
      } else {
        this.rangeTime = [null, null];
      }
      this.titleAdd = item.title;
      this.titleWatch = item.title;
      this.stateAdd = item.state;
      this.addChildrenTaskShow = true;
      this.getChangeLogList();
    },
    getChangeLogList() {
      api.changeLogList({ taskId: this.id }).then((res) => {
        if (res.result == 200) {
          this.changeLogList = res.data.changeLogList;
          this.changeLogList.forEach((item) => {
            this.priorityList.forEach((priority) => {
              this.$set(
                item,
                "content",
                item.content.replace(priority.value, priority.label)
              );
            });
            this.stateList.forEach((state) => {
              this.$set(
                item,
                "content",
                item.content.replace(state.value, state.label)
              );
            });
            this.$set(item, "content", item.content.replace("待处理_", "待"));
            this.$set(item, "content", item.content.replace("_", ""));
            this.$set(
              item,
              "content",
              item.content.replace("DELIVERY", "发布")
            );
            this.$set(item, "content", item.content.replace("VERY", "非常"));
            this.$set(
              item,
              "content",
              item.content.replace(
                "<img",
                "<img style='max-width: 100%;max-height: 300px'"
              )
            );
            this.$set(item, "show", false);
          });
          if (!this.editKeyVisible) {
            this.editKeyVisible = true;
            this.$nextTick(() => {
              this.pasteImg(this.$refs.editQuillEditor);
            });
          }
          this.$nextTick(() => {
            this.$refs.changeLogList.scrollTop =
              this.$refs.changeLogList.scrollHeight;
          });
        }
      });
    },
    // 确定新增
    add_submit(next) {
      let executorList = [];
      this.executorListAdd.forEach((item) => {
        executorList.push(item.loginName);
      });
      let data = {
        title: this.titleAdd,
        executorList,
        state: this.stateAdd,
        startDate: this.formatDateStartTime,
        endDate: this.formatDateEndTime,
        priority: this.priorityAdd,
        remark: this.remarkAdd,
        fileIdList: this.fileIdList,
        type: this.type,
      };
      if (this.childrenShow) {
        data.parentId = this.parentId;
      }
      if (data.type == "SCHEDULE_TASK") {
        data.schedule = JSON.stringify(this.schedule);
      }
      api
        .addWorkTask(data)
        .then((res) => {
          if (res.result == 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
            if (next) {
              this.add();
            }
          }
        })
        .catch(() => {
          this.addKeyVisible = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.action-class ::v-deep .ql-formats {
  margin-right: 0 !important;
}
.priority ::v-deep .ant-form-item-label {
  line-height: normal !important;
}

.drawer {
  .projectMemberList {
    margin-top: 20px;
    .projectMember {
      padding: 5px 0;
      .add {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 34px;
        text-align: center;
        background-color: #2ca9f4;
        margin-right: 10px;
        border-radius: 100%;
      }
      .delete {
        height: 40px;
        line-height: 42px;
        float: right;
        cursor: pointer;
        &:hover {
          color: #2ca9f4;
        }
      }
    }
  }
}

.action-class {
  ::v-deep .ant-modal-body {
    padding: 0 !important;
  }
  .flex {
    display: flex;
    flex-wrap: nowrap;
    min-height: 500px;
    .flexItem {
      height: 790.75px;
      .scroll_y {
        height: calc(100% - 118.31px);
        overflow-y: scroll;
      }
      .changeLogList {
        height: 600px;
        padding: 0 24px;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        overflow-y: scroll;
        padding-top: 18px;
        &::-webkit-scrollbar {
          display: none;
        }
        .OPERATION {
          overflow: auto;
          margin-bottom: 18px;
          font-size: 12px;
          color: #8c8c8c;
        }
        .COMMENT {
          overflow: auto;
          margin-bottom: 18px;
          font-size: 12px;
          color: #8c8c8c;
        }
      }
      .comment {
        padding: 16px;
        .commentInput {
          font-size: 14px;
          line-height: 20px;
          height: 20px;
          color: #000;
        }
      }
    }
  }
  .title {
    border: none !important;
    padding: 0 !important;
    font-size: 16px;
    resize: none;
    overflow-y: hidden;
    margin-top: 10px;
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .editTitle {
    border: none !important;
    padding: 0 !important;
    font-size: 18px;
    resize: none;
    overflow-y: hidden;
    padding: 0 10px !important;
    font-weight: 500;
    color: #000;
    &:hover {
      background: #f7f7f7;
    }
    &:focus {
      background: #f7f7f7;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .col {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    padding-bottom: 8px;
    margin-top: 20px;
    .executorList {
      position: relative;
      display: flex;
      margin-right: 30px;
      .executor {
        line-height: 32px;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 9999px;
        box-sizing: border-box;
        position: relative;

        span {
          vertical-align: middle;
        }
        &:hover {
          background: #f7f7f7;

          .name {
            color: #1b9aee;
          }
        }
        .icon_remove {
          font-size: 16px;
          display: none;
          vertical-align: top;
          position: absolute;
          right: -6px;
          top: -6px;
          z-index: 1;
          cursor: pointer;
          color: #bfbfbf;
          width: 20px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
        }
      }
      .nameList {
        position: absolute;
        width: 250px;
        left: 0;
        top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        z-index: 9999;
        box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
        .search {
          padding: 16px;
        }
        .userlist {
          width: 100%;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .userItem {
            padding: 8px 16px;
            cursor: pointer;
            &:hover {
              background: #f7f7f7;
              color: #1b9aee;
            }
          }
        }
      }
    }
    .schedule {
      position: relative;
      .scheduleButton {
        color: #8c8c8c;
        font-size: 16px;
        &:hover {
          color: #1890ff;
        }
      }
      .schedulePanel {
        position: absolute;
        left: 0;
        top: 32px;
        border-radius: 4px;
        box-shadow: 0 7px 21px rgb(0 0 0 / 10%);
        width: 250px;
        background-color: #fff;
        color: grey;
        z-index: 999999;
        padding-bottom: 15px;
        .schedulePanelItem {
          margin: 12px 15px;
          .dayList {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            .option {
              font-size: 14px;
              margin: -1px 0 0 -1px;
              display: inline-block;
              width: 30px;
              height: 30px;
              line-height: 30px;
              border: 1px solid #ededed;
              text-align: center;
              cursor: pointer;
              &:hover {
                background-color: #f7f7f7;
              }
            }
            .checkOption {
              background-color: #1b9aee !important;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .formItem {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 12px auto;
    .label {
      text-align: left;
      width: 10%;
      padding: 0 10px;
      color: #8c8c8c;
      line-height: 32px;
    }
    .value {
      width: 90%;
      .editorHide {
        padding: 8px;
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        ::v-deep img {
          max-width: 100%;
          max-height: 300px;
        }

        &:hover {
          background-color: #f7f7f7;
          border-radius: 4px;
        }
      }
      ::v-deep .ql-container {
        height: 200px !important;
      }
    }
  }
  .fileList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #e5e5e5;
    padding: 20px 0;
    .file {
      width: 20%;
      text-align: center;
      cursor: pointer;
      position: relative;
      padding: 15px 0;
      &:hover {
        background-color: #f7f7f7;
        border-radius: 5px;
      }

      .bg {
        width: 60%;
        margin: 0 auto;
        height: 77px;
        background-image: url("../assets/fileBg.png");
        background-size: 100% 100%;
        background-position: center;
        line-height: 95px;
        text-align: center;
      }
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        padding: 0 5px;
      }
      .delete {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .spaceFile {
      width: 20%;
    }
  }
  .priority {
    width: 120px;
    ::v-deep .ant-select-selection {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .ant-select-arrow {
        display: none;
      }
    }
  }
  .childrenTask {
    margin: 10px 24px 0 34px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    .child {
      display: flex;
      flex-wrap: nowrap;
      padding: 5px 0;
      .select {
        line-height: 37px;
      }
      .childTitle {
        padding: 8px;
        width: 25%;
        display: inline-block;
        white-space: nowrap;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
        &:hover {
          background-color: #f7f7f7;
        }
      }
      .executorList {
        position: relative;
        display: flex;
        margin-right: 30px;
        .executor {
          line-height: 36px;
          padding: 0 5px;
          cursor: pointer;
          border-radius: 9999px;
          box-sizing: border-box;
          position: relative;

          span {
            vertical-align: middle;
          }
          &:hover {
            background: #f7f7f7;

            .name {
              color: #1b9aee;
            }
          }
          .icon_remove {
            font-size: 16px;
            display: none;
            vertical-align: top;
            position: absolute;
            right: -6px;
            top: -6px;
            z-index: 1;
            cursor: pointer;
            color: #bfbfbf;
            width: 20px;
            height: 20px;
            line-height: 20px;
            display: inline-block;
          }
        }
        .nameList {
          position: absolute;
          width: 250px;
          left: 0;
          top: 40px;
          background-color: #ffffff;
          border-radius: 4px;
          z-index: 9999;
          box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
          .search {
            padding: 16px;
          }
          .userlist {
            width: 100%;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            .userItem {
              padding: 8px 16px;
              cursor: pointer;
              &:hover {
                background: #f7f7f7;
                color: #1b9aee;
              }
            }
          }
        }
      }
    }
  }
}

.participantList {
  position: relative;
  display: flex;
  margin-right: 30px;
  padding: 0 16px 16px;

  .addParticipant {
    border-radius: 50%;
    background-color: #1b9aef;
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-align: center;
    line-height: 25px;
    margin-top: 4px;
  }
  .participant {
    line-height: 32px;
    padding: 0 5px;
    cursor: pointer;
    border-radius: 9999px;
    box-sizing: border-box;
    position: relative;

    span {
      vertical-align: middle;
    }
    &:hover {
      background: #f7f7f7;

      .name {
        color: #1b9aee;
      }
    }
    .icon_remove {
      font-size: 16px;
      display: none;
      vertical-align: top;
      position: absolute;
      right: -6px;
      top: -6px;
      z-index: 1;
      cursor: pointer;
      color: #bfbfbf;
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: inline-block;
    }
  }
  .nameList {
    position: absolute;
    width: 250px;
    left: 0;
    top: 50px;
    background-color: #ffffff;
    border-radius: 4px;
    z-index: 9999;
    box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
    .search {
      padding: 16px;
    }
    .userlist {
      width: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .userItem {
        padding: 8px 16px;
        cursor: pointer;
        &:hover {
          background: #f7f7f7;
          color: #1b9aee;
        }
      }
    }
  }
}

.request-search-button {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  line-height: 32px;
  .screen {
    cursor: pointer;
    padding: 0 8px;
    border-radius: 4px;
    color: #595959;
    transition: color, background-color 218ms;
    &:hover {
      color: #262626;
      background-color: #e5e5e5;
    }
  }
  .screenHover {
    color: #262626;
    background-color: #e5e5e5;
  }
  .screenPanel {
    width: 380px;
    padding-top: 25px;
    position: fixed;
    top: 135px;
    right: 40px;
    border-radius: 4px;
    border: 0 solid transparent;
    background-color: #fff;
    box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
    z-index: 900;
    transition: all 100ms;
  }
}
.workbench {
  height: calc(100vh - 192px);
  background-color: #f7f7f7;
  .tableRow {
    color: #8c8c8c;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    background-color: #ffffff;
    .tableHead {
      height: 40px;
      overflow: hidden;

      div {
        height: 40px;
        line-height: 40px;

        .ml {
          margin-left: 20px;
          -webkit-user-select: none;
          user-select: none;
          .button {
            color: #8c8c8c;
            &:hover {
              color: #1890ff;
            }
          }
        }
        .center {
          -webkit-user-select: none;
          user-select: none;
          .button {
            color: #8c8c8c;
            &:hover {
              color: #1890ff;
            }
          }
        }
      }
      .row {
        border-right: 1px solid #e5e5e5;
        position: relative;
        float: left;

        &:hover {
          background: #f7f7f7;
          cursor: pointer;
        }
        .borderRight {
          position: absolute;
          top: 0;
          right: 0;
          content: "";
          border-right: 2px solid transparent;
        }
        .borderHover {
          border-right: 2px solid #1b9aee;
          cursor: col-resize;
        }
      }
    }
    .tableContent {
      max-height: calc(100vh - 334px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
