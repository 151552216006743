import { render, staticRenderFns } from "./domainAdmin.vue?vue&type=template&id=866b59aa&scoped=true"
import script from "./domainAdmin.vue?vue&type=script&lang=js"
export * from "./domainAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "866b59aa",
  null
  
)

export default component.exports