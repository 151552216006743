<template>
  <div class="slb">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left"></icon-font>
        {{ name }} | {{ protocol }}
      </span>
    </div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="规则列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "slb",
  data() {
    return {
      active: "1",
      id: "",
      name: "",
      protocol: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/monitor/slb/ruleList") {
          this.active = "1";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/monitor/slb/ruleList") return;
          this.$router.replace({
            path: "/monitor/slb/ruleList",
            query: { id: this.id },
          });
        }
      },
    },
  },
  created() {
    let id = this.$route.query.id * 1;
    if (id) {
      this.id = id;
      this.name = this.$route.query.name;
      this.protocol = this.$route.query.protocol + ":" + this.$route.query.port;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.slb {
  .back {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 500;
    padding-bottom: 10px;
  }
}
</style>
