import http from "./axios";

export const jvmInfo = ({ relationId, jvmInfoId }) => {
  return http.get("/performance/jvmInfo", {
    relationId,
    jvmInfoId,
  });
};

export const reloadGcDetail = ({
  relationId,
  jvmInfoId,
  startTime,
  endTime,
}) => {
  return http.get("/performance/reloadGcDetail", {
    relationId,
    jvmInfoId,
    startTime,
    endTime,
  });
};

export const jvmInfoSnapshotList = ({ relationId, name }) => {
  return http.get("/performance/jvmInfoSnapshotList", {
    relationId,
    name,
  });
};

export const uploadFileFlameGraphs = ({ file }) => {
  return http.files("/performance/uploadFileFlameGraphs", {
    file,
  });
};

export const saveJvmInfoSnapshot = ({ jvmInfo, relationId, name }) => {
  return http.json_post("/performance/saveJvmInfoSnapshot", {
    jvmInfo,
    relationId,
    name,
  });
};
