<template>
  <div class="ansiblePlaybook">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="ansibleName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="addTask(record)">创建任务</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="ansibleNameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="path" placeholder="请输入路径" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 创建任务 -->
    <a-modal
      title="创建任务"
      v-model="addTaskVisible"
      :maskClosable="false"
      :afterClose="addTaskClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverIdList"
            placeholder="请选择服务器"
            showSearch
            :filter-option="false"
            option-filter-prop="children"
            @search="serverIdSearch"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in instanceList" :key="item.id">{{
              item.instanceName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addTaskVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addTask_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ansiblePlaybook.js";
import { serverList } from "../lib/statusList.js";

export default {
  name: "ansiblePlaybook",
  data() {
    return {
      title: "",
      ansibleName: "",
      ansibleNameAdd: "",
      path: "",
      ansibleId: "",
      remark: "",
      addKeyVisible: false,
      addTaskVisible: false,
      serverIdList: [],
      instanceList: [],
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "路径",
          ellipsis: true,
          dataIndex: "path",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.ansibleName,
      };
      this.getPageList(data);
    },
    // 查询分页列表
    getPageList(data) {
      this.loadingTable = true;
      api
        .getPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.ansibleId = val.id;
      this.ansibleNameAdd = val.name;
      this.path = val.path;
      this.remark = val.remark;
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.ansibleNameAdd = "";
      this.path = "";
      this.remark = "";
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.ansibleNameAdd,
        path: this.path,
        remark: this.remark,
      };
      if (this.title === "新增") {
        api.add(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.ansibleId;
        api.edit(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.ansibleNameAdd = "";
      this.path = "";
      this.ansibleId = "";
      this.remark = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.ansibleDelete(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 服务器下拉框发生变化
    serverIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 创建任务
    addTask(val) {
      this.ansibleId = val.id;
      this.addTaskVisible = true;
      this.getServerList();
    },
    addTaskClose() {
      this.ansibleId = "";
      this.serverIdList = [];
    },
    addTask_submit() {
      let data = {
        ansibleId: this.ansibleId,
        serverIdList: this.serverIdList,
      };
      api.addAnsibleTask(data).then((res) => {
        if (res.result === 200) {
          this.addTaskVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
