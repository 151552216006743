<template>
  <div class="projectPom">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目：
        <a-select
          v-model="projectId"
          placeholder="请选择项目"
          showSearch
          :filter-option="false"
          @search="getProjectList"
          allowClear
        >
          <a-select-option
            v-for="(i, index) in projectList"
            :key="index"
            :value="i.id"
          >
            {{ i.enName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        查询子项：
        <a-checkbox v-model="searchChild"></a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
    </a-table>
  </div>
</template>
<script>
import api from "../lib/projectPom.js";
import { projectList } from "../lib/projectList.js";

export default {
  name: "projectPom",
  data() {
    return {
      projectId: undefined,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "父级名称",
          ellipsis: true,
          dataIndex: "parentName",
        },
        {
          title: "项目名称",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
        },
        {
          title: "范围",
          ellipsis: true,
          dataIndex: "scope",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      name: "",
      projectList: [],
      searchChild: false,
    };
  },
  mounted() {
    this.query(1);
    this.getProjectList();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        projectId: this.projectId,
        name: this.name,
        searchChild: this.searchChild,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getProjectList(val) {
      this.projectList.splice(0);
      let data = {
        pageNo: 1,
        pageSize: 10,
        name: val,
      };
      projectList(data).then((res) => {
        if (res.result == 200) {
          this.projectList.push(...res.data.list);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
