<template>
  <div class="xTerm" id="xTerm">
    <div :id="xTermData.key"></div>
  </div>
</template>
<script>
import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
// import { AttachAddon } from "xterm-addon-attach";
import { WebLinksAddon } from "xterm-addon-web-links";
import "../lib/zmodem";
import { cookieInfo } from "../lib/terminal";

export default {
  name: "xTerm",
  props: {
    fullscreen: {
      type: Boolean,
      default: function () {
        return {};
      },
    },
    xTermData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    term: {
      type: Object,
      default: function () {
        return {};
      },
    },
    loginNickName: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      socketURI: "wss://delivery.huiyu.org.cn/ws/sshShell",
      socket: null,
      fitAddon: null,
      currentReceiveXfer: null,
      zsession: null,
      rows: 40,
      cols: 100,
      cookie: "",
      timer: null,
    };
  },
  watch: {
    //intrd是需要监听的数据
    xTermData(newval, oldval) {
      this.xTermData = newval; //newval为监听到发生改变数据
    },
    fullscreen: {
      handler(newVal) {
        this.$forceUpdate();
      },
    },
  },
  created() {
    this.getCookieInfo();
  },
  mounted() {
    this.$watermark.set(this.loginNickName, document.getElementById("xTerm"));
  },
  methods: {
    // 获取目录列表
    getCookieInfo() {
      cookieInfo()
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            this.cookie = list.value;
            this.initSocket();
          }
        })
        .catch((err) => {});
    },
    initTerm() {
      let offsetHeight = document.querySelector(".content_cc").offsetHeight;
      this.rows = (offsetHeight - 40) / 17;
      this.term[this.xTermData.key] = new Terminal({
        // fontSize: 13,
        rendererType: "canvas", //渲染类型
        rows: parseInt(this.rows), //行数
        cols: parseInt(this.cols), // 不指定行数，自动回车后光标从下一行开始
        convertEol: true, //启用时，光标将设置为下一行的开头
        scrollback: 10000, //终端中的回滚量
        disableStdin: false, //是否应禁用输入。
        cursorStyle: "underline", //光标样式
        cursorBlink: true, //光标闪烁
        theme: {
          foreground: "#ffffff", //字体
          // foreground: "#7e9192", //字体
          background: "#1e1b1b", //背景色
          // background: "#002833", //背景色
          cursor: "help", //设置光标
          lineHeight: 16,
        },
      });
      // const attachAddon = new AttachAddon(this.socket);
      // canvas背景全屏
      this.fitAddon = new FitAddon();
      // this.term[this.xTermData.key].loadAddon(attachAddon);
      this.term[this.xTermData.key].loadAddon(new WebLinksAddon());
      this.term[this.xTermData.key].zmodemRetract = () => {
        console.log("------retract----");
      };
      this.term[this.xTermData.key].zmodemDetect = (detection) => {
        console.log("------zmodemDetect----");
        (() => {
          const zsession = detection.confirm();

          let promise;

          if (zsession.type === "receive") {
            promise = this.handleReceiveSession(zsession);
          } else {
            promise = this.handleSendSession(zsession);
          }

          promise.catch(console.error.bind(console)).then(() => {
            console.log("----promise then-----");
          });
        })();
      };
      this.term[this.xTermData.key].zmodemAttach(this.socket, {
        noTerminalWriteOutsideSession: true,
      });
      this.term[this.xTermData.key].loadAddon(this.fitAddon);
      this.term[this.xTermData.key].open(
        document.getElementById(this.xTermData.key)
      );
      this.fitAddon.fit();

      window.addEventListener("resize", this.resizeScreen);
      this.term[this.xTermData.key].focus();
      // this.timer = setTimeout(() => {
      //   this.term[this.xTermData.key].write("ls\r\n");
      // }, 500);
      if (this.term[this.xTermData.key]._initialized) {
        return;
      }
      // 初始化
      this.term[this.xTermData.key]._initialized = true;
      // / **
      //     *添加事件监听器，用于按下键时的事件。事件值包含
      //     *将在data事件以及DOM事件中发送的字符串
      //     *触发了它。
      //     * @返回一个IDisposable停止监听。
      //  * /
      //   / ** 更新：xterm 4.x（新增）
      //  *为数据事件触发时添加事件侦听器。发生这种情况
      //  *用户输入或粘贴到终端时的示例。事件值
      //  *是`string`结果的结果，在典型的设置中，应该通过
      //  *到支持pty。
      //  * @返回一个IDisposable停止监听。
      //  * /
      // // 支持输入与粘贴方法
      this.term[this.xTermData.key].onData((ev) => {
        if (!this.socket) {
          this.initSocket();
          return;
        }
        this.onSend(ev);
      });
    },
    // 下载文件处理
    handleReceiveSession(zsession) {
      zsession.on("offer", (xfer) => {
        this.currentReceiveXfer = xfer;

        const onFormSubmit = () => {
          // 开始下载
          const FILE_BUFFER = [];
          xfer.on("input", (payload) => {
            // 下载中
            this.updateProgress(xfer);
            FILE_BUFFER.push(new Uint8Array(payload));
          });
          xfer.accept().then(() => {
            // 下载完毕，保存文件
            this.saveToDisk(xfer, FILE_BUFFER);
          }, console.error.bind(console));
        };

        onFormSubmit();
      });

      const promise = new Promise((res) => {
        zsession.on("session_end", () => {
          console.log("-----zession close----");
          this.stopSendProgress();
          res();
        });
      });

      zsession.start();

      return promise;
    },
    // 获取进度，可以发送给后台，展示在页面上
    updateProgress(xfer) {
      const fileName = xfer.get_details().name;
      const totalIn = xfer.get_offset();
      const percentReceived = (100 * totalIn) / xfer.get_details().size;
      this.currentProcess = percentReceived.toFixed(2);
    },
    // 下载完毕，保存文件
    saveToDisk(xfer, buffer) {
      this.term[this.xTermData.key].zmodemBrowser.save_to_disk(
        buffer,
        xfer.get_details().name
      );
    },
    // 上传文件处理
    handleSendSession(zsession) {
      // 展示上传文件modal
      // this.setState({
      //   uploadVisible: true
      // });
      this.zsession = zsession;

      const promise = new Promise((res, rej) => {
        zsession.on("session_end", () => {
          console.log("-----zession close----");
          res();
          this.zsession = null;
        });
      });

      return promise;
    },
    // 取消上传
    uploadCancel() {
      // this.setState({
      //   uploadVisible: false,
      //   fileList: []
      // });
      // // 发送信号给后台，取消上传
      // this.socket.send(
      //   JSON.stringify({
      //     Op: 'stdin',
      //     data: '\x18\x18\x18\x18\x18\x08\x08\x08\x08\x08'
      //   })
      // );
    },
    // 确定上传
    uploadSubmit() {
      if (!this.zsession) {
        return;
      }
      if (this.state.fileList.length) {
        const filesObj = this.state.fileList.map((el) => el.originFileObj);
        this.setState({
          uploadVisible: false,
          fileList: [],
        });

        try {
          this.xterm.zmodemBrowser
            .send_files(this.zsession, filesObj, {
              on_offer_response: (obj, xfer) => {
                if (xfer) {
                  this.socket.send(
                    JSON.stringify({ Op: "progress", data: "\n" })
                  );
                  this.currentFileName = xfer.get_details().name;
                  xfer.on("send_progress", (percent) => {
                    // 上传中，发送进度给后台
                  });
                }
              },
              on_file_complete: (obj, xfer) => {
                // 完毕后发100%给后台
              },
            })
            .then(this.stopSendProgress)
            .then(
              this.zsession.close.bind(this.zsession),
              console.error.bind(console)
            )
            .then(() => {
              this.stopSendProgress();
            });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        message.error("至少上传一个文件");
      }
    },
    // 停止发送进度
    stopSendProgress() {},
    // 内容全屏显示
    resizeScreen() {
      this.fitAddon.fit();
    },
    // 创建socket
    initSocket() {
      this.socket = new WebSocket(this.socketURI);
      this.socketOnClose();
      this.socketOnMessage();
      this.socketOnOpen();
      this.socketOnError();
    },
    // 链接成功后
    socketOnOpen() {
      this.socket.onopen = () => {
        console.log("建立连接");
        if (this.term[this.xTermData.key]) {
          this.term[this.xTermData.key].dispose();
          this.term[this.xTermData.key] = null;
        }
        this.initTerm();
        let data = {
          name: "ESTABLISH",
          serverAccountId: this.xTermData.serverAccountId,
          widthCharacters: this.term[this.xTermData.key].cols,
          heightCharacters: this.term[this.xTermData.key].rows,
          uuid: this.cookie,
        };
        this.onSend(data, "xtermEvent:");
      };
    },
    // socket消息
    socketOnMessage() {
      this.socket.onmessage = (e) => {
        this.term[this.xTermData.key].write(e.data);
      };
    },
    // socket关闭
    socketOnClose() {
      this.socket.onclose = () => {
        console.log("WebSocket连接关闭");
        this.socket = null;
      };
    },
    // socket失败
    socketOnError() {
      this.socket.onerror = () => {
        console.log("socket 链接失败");
      };
    },
    // socket发送
    onSend(data, sgin) {
      data = typeof data === "object" ? JSON.stringify(data) : data;
      data = data.constructor === Array ? data.toString() : data;
      data = data.replace(/\\\\/, "\\");
      if (sgin) {
        data = sgin + data;
      }
      this.socket.send(data);
    },
    //删除左右两端的空格
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    window.removeEventListener("resize", this.resizeScreen);
  },
};
</script>
<style lang="scss" scoped>
.xTerm {
  width: 100%;
  padding: 5px;
  &::v-deep .xterm-viewport::-webkit-scrollbar {
    display: none;
  }
}
</style>
