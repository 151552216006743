<template>
  <div class="bg">
    <div class="slider" ref="slider" @click="handelClickSlider">
      <div class="process" :style="{ width }"></div>
      <div
        class="thunk"
        ref="trunk"
        :style="{ left }"
        @click.stop="thunkStop"
        @mouseenter.stop="moveInit"
      >
        <div class="block" ref="dot"></div>
      </div>
    </div>
    <div class="button" @click="playOrStop">
      <icon-font type="icon-stop" v-if="stop"></icon-font>
      <icon-font type="icon-play" v-else></icon-font>
    </div>
  </div>
</template>

<script>
/*
 * min 进度条最小值
 * max 进度条最大值
 * v-model 对当前值进行双向绑定实时显示拖拽进度
 * */
export default {
  props: {
    // 最小值
    min: {
      type: Number,
      default: 0,
    },
    // 最大值
    max: {
      type: Number,
      default: 100,
    },
    // 当前值
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      slider: null, //滚动条DOM元素
      per: 0, //当前值
      stop: true,
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.per = newVal;
        if (this.per >= this.max) {
          this.stop = false;
        }
      },
    },
  },
  mounted() {
    this.slider = this.$refs.slider;
    this.per = this.value;
  },
  methods: {
    thunkStop() {
      // 禁止事件向上传递
    },
    playOrStop() {
      if (this.per < this.max) {
        this.$emit("playOrStop", Math.round(this.per), this.stop);
        this.stop = !this.stop;
      }
    },
    moveInit() {
      this.$refs.trunk.onmousedown = (e) => {
        if (e.button == 2) {
          return;
        }
        var width = parseInt(this.width);
        var disX = e.clientX;
        document.body.onmousemove = (e) => {
          var newWidth = e.clientX - disX + width;
          var scale = newWidth / this.slider.offsetWidth;
          this.per = Math.ceil((this.max - this.min) * scale + this.min); //取整
          this.per = Math.max(this.per, this.min);
          this.per = Math.min(this.per, this.max);
        };
        document.body.onmouseup = () => {
          this.$emit("change", Math.round(this.per));
          this.stop = true;
          document.body.onmousemove = document.body.onmouseup = null;
        };
      };
    },
    handelClickSlider(event) {
      const dot = this.$refs.dot;
      if (event.target == dot) return;
      //获取元素的宽度l
      let width = this.slider.offsetWidth;
      //获取元素的左边距
      let ev = event || window.event;
      //获取当前点击位置的百分比
      let scale = ((ev.offsetX / width) * (this.max - this.min)).toFixed(0);
      this.per = scale * 1 + this.min;
      this.$emit("change", Math.round(this.per));
      this.stop = true;
    },
  },
  computed: {
    // 设置一个百分比，提供计算slider进度宽度和trunk的left值
    // 对应公式为  当前值-最小值/最大值-最小值 = slider进度width / slider总width
    // trunk left =  slider进度width + trunk宽度/2
    scale() {
      return (this.per - this.min) / (this.max - this.min);
    },
    width() {
      return this.slider ? this.slider.offsetWidth * this.scale + "px" : "0px";
    },
    left() {
      return this.slider
        ? this.slider.offsetWidth * this.scale -
            this.$refs.trunk.offsetWidth / 2 +
            "px"
        : "0px";
    },
  },
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.button {
  cursor: pointer;
  margin: 0 15px;
}
.slider {
  position: relative;
  width: calc(100% - 14px);
  height: 5px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  z-index: 99999;
}
.slider .process {
  position: absolute;
  left: 0;
  top: 0;
  width: 112px;
  height: 5px;
  border-radius: 5px;
  background: #4ab157;
  z-index: 111;
}
.slider .thunk {
  position: absolute;
  left: 0;
  top: -2.5px;
  z-index: 122;
}
.slider .block {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  transition: 0.2s all;
}
.slider .block:hover {
  transform: scale(1.1);
  opacity: 0.6;
}
</style>
