<template>
  <div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="申请列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="发布日志" force-render v-if="!$route.query.uuid">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="项目状态">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="运行操作日志">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "request",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/request/postApplication") {
          this.active = "1";
        } else if (newVal == "/request/publishLog") {
          this.active = "2";
        } else if (newVal == "/request/statusTabList") {
          this.active = "3";
        } else if (newVal == "/request/statusTabLog") {
          this.active = "4";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/request/postApplication") return;
          this.$router.replace("/request/postApplication");
        } else if (newVal === "2") {
          if (this.$route.path == "/request/publishLog") return;
          this.$router.replace("/request/publishLog");
        } else if (newVal === "3") {
          if (this.$route.path == "/request/statusTabList") return;
          this.$router.replace("/request/statusTabList");
        } else {
          if (this.$route.path == "/request/statusTabLog") return;
          this.$router.replace("/request/statusTabLog");
        }
      },
    },
  },
};
</script>
