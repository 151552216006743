import http from "./axios";

// 查询模板列表
export const codeTemplatePage = ({
  name,
  category,
  pageNo,
  pageSize,
  isSystemTemplate,
  group,
}) => {
  return http.get("/generatorCode/codeTemplatePage", {
    name: name,
    pageNo: pageNo,
    pageSize: pageSize,
    isSystemTemplate: isSystemTemplate,
    category: category,
    group,
  });
};

// 新增模板
export const addCodeTemplate = ({
  name,
  category,
  enabled,
  templateId,
  group,
}) => {
  return http.json_post("/generatorCode/addCodeTemplate", {
    name: name,
    templateId: templateId,
    enabled: enabled,
    category: category,
    group,
  });
};

// 编辑模板
export const editCodeTemplate = ({
  id,
  category,
  name,
  enabled,
  templateId,
  group,
}) => {
  return http.json_post("/generatorCode/editCodeTemplate", {
    id: id,
    name: name,
    templateId: templateId,
    enabled: enabled,
    category: category,
    group,
  });
};

// 删除模板
export const deleteCodeTemplate = ({ id }) => {
  return http.get("/generatorCode/deleteCodeTemplate", {
    id: id,
  });
};

// 修改模板内容
export const editCodeTemplateContent = ({ id, content }) => {
  return http.json_post("/generatorCode/editCodeTemplateContent", {
    id: id,
    content: content,
  });
};

// 模板类型
export const templateTypeList = () => {
  return http.get("/generatorCode/templateTypeList", {});
};

// 模板集合
export const codeTemplateList = ({ name, isSystemTemplate, category }) => {
  return http.get("/generatorCode/codeTemplateList", {
    name: name,
    isSystemTemplate: isSystemTemplate,
    category: category,
  });
};

// 类别下拉框
export const codeTemplateCategoryList = () => {
  return http.get("/generatorCode/codeTemplateCategoryList", {});
};
