<template>
  <div class="nginxGateway">
    <div class="searchCondition">
      <div class="searchConditionItem">
        监听服务器组：
        <a-select
          v-model="serverGroupId"
          showSearch
          placeholder="请选择监听服务器组"
          :filter-option="false"
          @search="serverGroupIdSearch"
          allowClear
        >
          <a-select-option v-for="item in serverGroupList" :key="item.id">{{
            item.groupName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="groupName" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 查看服务器 </span>
          <a-button type="link" @click="useClick(record)">{{ text }}</a-button>
        </a-tooltip>
      </span>
      <span slot="port" slot-scope="text, record">
        {{ record.protocol + ":" + text }}
      </span>
      <span slot="dns" slot-scope="text, record">
        <div
          v-show="text"
          @mouseover="record.pubDnsShow = true"
          @mouseout="record.pubDnsShow = false"
        >
          <span>{{ text }} (外网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.pubDnsShow"
            />
          </a-tooltip>
        </div>
        <div
          v-show="record.privateDns"
          @mouseover="record.priDnsShow = true"
          @mouseout="record.priDnsShow = false"
        >
          <span>{{ record.privateDns }} (内网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="record.privateDns"
              v-show="record.priDnsShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="monitor(record)">端口监听</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="certificate(record, 1)">证书管理</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="leftTop"
          title="确认删除？"
          @confirm="() => deleteClick(record)"
        >
          <a href="javascript:;" style="color: red">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="监听服务器组:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverGroupIdAdd"
            showSearch
            placeholder="请选择监听服务器组"
            :filter-option="false"
            @search="serverGroupIdSearch"
          >
            <a-select-option v-for="item in serverGroupList" :key="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="dns域名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="domainId"
            showSearch
            placeholder="请选择dns域名"
            option-filter-prop="children"
            allowClear
          >
            <a-select-option
              v-for="item in domainList"
              :key="item.id"
              :value="item.id"
              >{{ item.domainName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="监听协议:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="protocolType"
            showSearch
            placeholder="请选择监听协议"
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in protocolTypeList" :key="item.key">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="端口:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="port" placeholder="请输入端口" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="证书"
      v-model="certificateVisible"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchButton">
        <a-button type="primary" @click="certificate()" icon="search"
          >查询</a-button
        >
        <a-button type="primary" @click="addCertificate" icon="plus"
          >新增</a-button
        >
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(certificateTableColumns)"
        :columns="certificateTableColumns"
        :dataSource="certificateTableDataSource"
        :pagination="certificateTablePagination"
        :loading="certificateLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            title="确认删除？"
            @confirm="() => deleteCertificateClick(record)"
          >
            <a href="javascript:;" style="color: red">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="certificateVisible = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="新增证书"
      v-model="addCertificateVisible"
      :maskClosable="false"
      :afterClose="addCertificateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="证书:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="certificateId"
            showSearch
            placeholder="请选择证书"
            :filter-option="false"
            @search="certificateIdSearch"
            allowClear
          >
            <a-select-option v-for="item in certificateList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addCertificateVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addCertificate_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/nginxGateway.js";
import { getPage } from "../lib/certificateManagement.js";
import { domainNameList } from "../lib/domainAdmin.js";
import { listNginxServerGroup } from "../lib/nginxServerGroup.js";

export default {
  name: "nginxGateway",
  data() {
    return {
      serverGroupId: undefined,
      serverGroupIdAdd: undefined,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "网关编码",
          ellipsis: true,
          dataIndex: "code",
        },
        {
          title: "监听服务器组",
          ellipsis: true,
          dataIndex: "listenServerGroupName",
          scopedSlots: { customRender: "groupName" },
        },
        {
          title: "监听协议/端口",
          ellipsis: true,
          dataIndex: "port",
          scopedSlots: { customRender: "port" },
        },
        {
          title: "dns",
          ellipsis: true,
          dataIndex: "publicDns",
          scopedSlots: { customRender: "dns" },
          width: 400,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      serverGroupList: [],
      certificateVisible: false,
      certificatePageNo: 1,
      certificateTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "证书名称",
          ellipsis: true,
          dataIndex: "certificateName",
        },

        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      certificateTableDataSource: [],
      certificateTablePagination: {},
      certificateLoadingTable: false,
      nginxGatewayId: "",
      certificateId: undefined,
      certificateList: [],
      addCertificateVisible: false,
      ip: "",
      protocolTypeList: [
        {
          key: "HTTP",
          value: "HTTP",
        },
        {
          key: "HTTPS",
          value: "HTTPS",
        },
      ],
      protocolType: "",
      port: "",
      domainId: undefined,
      domainList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getServerList();
    this.getDomainNameList();
  },
  methods: {
    useClick(val) {
      this.$router.push({
        path: "/serverGroupList/listenServerGroupList/listenServerGroupItemList",
        query: {
          groupId: val.listenServerGroupId,
          groupName: val.listenServerGroupName,
        },
      });
    },
    getDomainNameList() {
      let data = {
        pageNo: 1,
        pageSize: 20,
      };
      domainNameList(data).then((res) => {
        if (res.result === 200) {
          this.domainList = res.data.records;
        }
      });
    },
    addCertificate_submit() {
      let data = {
        certificateId: this.certificateId,
        nginxGatewayId: this.nginxGatewayId,
      };
      api.addNginxGatewaySsl(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.addCertificateVisible = false;
          this.certificate();
        }
      });
    },
    certificateIdSearch(name) {
      this.getCertificatePage(name);
    },
    addCertificateClose() {
      this.certificateId = undefined;
    },
    // 查询证书
    getCertificatePage(name) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        name: name,
      };
      getPage(data)
        .then((res) => {
          if (res.result === 200) {
            this.certificateList = res.data.records;
            this.addCertificateVisible = true;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    addCertificate() {
      this.getCertificatePage();
    },
    monitor(val) {
      this.$router.push({
        path: "/monitor/slb",
        query: {
          id: val.id,
          name: val.listenServerGroupName,
          protocol: val.protocol,
          port: val.port,
        },
      });
    },
    deleteCertificateClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteNginxGatewaySsl(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.certificate();
        }
      });
    },
    certificate(val, index) {
      this.certificateLoadingTable = true;
      if (val) {
        this.nginxGatewayId = val.id;
      }
      if (index) {
        this.certificatePageNo = index;
      }
      let data = {
        pageNo: this.certificatePageNo,
        pageSize: 10,
        nginxGatewayId: this.nginxGatewayId,
      };
      api.nginxGatewaySslPage(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.certificateTableDataSource = list;
          this.certificateTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.certificateChangePageItem(current),
          };
          this.certificateVisible = true;
          this.certificateLoadingTable = false;
        }
      });
    },
    certificateChangePageItem(index) {
      this.certificate(null, index);
    },
    serverGroupIdSearch(groupName) {
      this.getServerList(groupName);
    },
    getServerList(groupName) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        groupName,
        type: "LISTEN",
      };
      listNginxServerGroup(data).then((res) => {
        if (res.result === 200) {
          this.serverGroupList = res.data.records;
        }
      });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        listenServerGroupId: this.serverGroupId,
        deployType: "CLUSTER",
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.oipNameShow = false;
              item.iipNameShow = false;
              item.pubDnsShow = false;
              item.priDnsShow = false;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    add() {
      this.title = "新增";
      this.serverGroupIdAdd = this.serverGroupList[0].id;
      this.protocolType = "HTTP";
      this.port = "80";
      this.domainId = this.domainList[0].id;
      this.addKeyVisible = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        listenServerGroupId: this.serverGroupIdAdd,
        protocolType: this.protocolType,
        port: this.port,
        deployType: "CLUSTER",
        domainId: this.domainId,
      };
      api.addData(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.serverGroupIdAdd = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
