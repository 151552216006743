<template>
  <div class="podList">
    <div class="reminder">容器组 <span>pod</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select
          v-model="statusList"
          placeholder="请选择状态"
          mode="multiple"
          @change="query"
          allowClear
        >
          <a-select-option
            v-for="(item, index) in statusSelectList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <a-checkbox
          v-model="customize"
          @change="query()"
          :disabled="name != ''"
        >
          自定义项目
        </a-checkbox>
      </div>
      <div class="searchConditionItem">
        <a-select
          v-model="category"
          placeholder="请选择"
          @change="query()"
          :disabled="name != ''"
        >
          <a-select-option
            v-for="(item, index) in customizeList"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="name" slot-scope="text, record">
        <a-tooltip overlayClassName="name">
          <template slot="title">
            Pod IP: {{ record.podIp }}<br />
            nodes:
            <template v-for="item in record.nodes"> <br />{{ item }} </template>
          </template>
          {{ record.name }}
        </a-tooltip>
      </span>
      <span slot="label" slot-scope="text, record">
        <template v-for="(value, key) in record.label">
          <a-tag style="margin-top: 5px"> {{ key }}:{{ value }} </a-tag>
        </template>
      </span>
      <span slot="nodes" slot-scope="text, record">
        <div v-for="item in record.nodes">
          {{ item }}
        </div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          style="padding: 0"
          @click="arthasDiagnosis(record)"
          :disabled="
            !(
              (record.label.category &&
                (record.label.category == 'java' ||
                  record.label.category == 'JAVA')) ||
              (record.label.huiyu_category &&
                (record.label.huiyu_category == 'java' ||
                  record.label.huiyu_category == 'JAVA'))
            )
          "
          >arthas诊断</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "podList",
  props: ["namespace"],
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "标签",
          dataIndex: "label",
          scopedSlots: { customRender: "label" },
          width: 200,
        },
        {
          title: "状态",
          dataIndex: "status",
        },
        {
          title: "重启次数",
          dataIndex: "restartCount",
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      selectedRowKeys: [],
      selectedRows: [],
      statusSelectList: [
        "Running",
        "Pending",
        "Succeeded",
        "Failed",
        "Unknown",
      ],
      statusList: [],
      customize: true,
      category: "",
      customizeList: [
        { label: "全部项目", value: "" },
        { label: "后端项目", value: "huiyu_category:JAVA" },
        { label: "前端项目", value: "category:front" },
      ],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
      },
    },
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
        status: this.statusList.join(","),
      };
      let labelList = [];
      if (this.customize) {
        labelList.push("deployer:beta-delivery");
      }
      if (this.category) {
        labelList.push(this.category);
      }
      data.labels = labelList.join(",");
      this.loadingTable = true;
      api
        .podList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    arthasDiagnosis(val) {
      window.open("https://arthas.huiyu.org.cn/?agentId=" + val.name);
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: val.namespace,
      };
      api.deletePod(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      this.$axios
        .all(
          this.selectedRows.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deletePod(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
<style lang="scss">
.name {
  .ant-tooltip-inner {
    width: 280px;
  }
}
</style>
