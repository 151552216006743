<template>
  <div class="certificateDomain">
    <div class="searchCondition">
      <div class="searchConditionItem">
        域名：
        <a-input
          placeholder="请输入域名"
          v-model.trim="host"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="status" slot-scope="text">
        <a-tag color="green" v-if="text == true">正常</a-tag>
        <a-tag color="red" v-if="text == false">过期</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="synClick(record)">同步过期时间</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="editClick(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.host }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="域名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="hostAdd" placeholder="请输入域名" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/certificateDomain.js";

export default {
  name: "certificateDomain",
  data() {
    return {
      certificateId: "",
      title: "",
      host: "",
      hostAdd: "",
      remark: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "域名",
          ellipsis: true,
          dataIndex: "host",
        },
        {
          title: "证书名称",
          ellipsis: true,
          dataIndex: "certName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "剩余天数",
          ellipsis: true,
          dataIndex: "expireDays",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        host: this.host,
      };
      this.getGroupPageList(data);
    },
    // 查询告警组分页列表
    getGroupPageList(data) {
      this.loadingTable = true;
      api
        .getPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.hostAdd = "";
      this.remark = "";
    },
    // 编辑
    editClick(val) {
      this.title = "编辑";
      this.addKeyVisible = true;
      this.hostAdd = val.host;
      this.remark = val.remark;
      this.certificateId = val.id;
    },
    // 确定新增
    add_submit() {
      let data = {
        host: this.hostAdd,
        remark: this.remark,
      };
      if (this.title == "新增") {
        api.addCertificate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else if (this.title == "编辑") {
        data.id = this.certificateId;
        api.editCertificate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.hostAdd = "";
      this.remark = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCertificate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 同步
    synClick(val) {
      let data = {
        id: val.id,
      };
      api.syn(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
